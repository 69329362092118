@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, smallWidth from variables;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.8rem;
}

.emptyFonts {
  width: 100%;
  height: 9rem;
}

@media screen and (max-width: smallWidth) {
  .title {
    font-size: 1.6rem;
  }
}
