@value variables: "../../../../assets/stylesheets/variables.scss";
@value steelColor, slateColor, iceColor, midnightColor, concreteColor from variables;

.container {
  width: 26rem;
  margin-left: 3.3rem;
  height: 100%;
}

.rectangle {
  padding: 1.6rem 2.4rem;
  border-radius: 6px;
  border: solid 1px steelColor;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 7.5rem;
  margin-bottom: .8rem;
}

.chkboxRectangle {
  padding: 1rem 2.4rem;
  border-radius: 6px;
  border: 0;
  background-color: concreteColor;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: .8rem;
  position: relative;
  min-height: 4.4rem;
}

.chkBoxText {
  font-size: 1.2rem;
  font-weight: 300;
  color: slateColor;
}

.infoIcon {
  padding-top: .2rem;
  padding-left: .2rem;
  height: 2.4rem;
  width: 2.4rem;
}

.title {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: slateColor;

  img {
    margin-right: .4rem;
  }
}

.info {
  font-size: 2.4rem;
  color: iceColor;
}

.spinnerContainer {
  flex: 1;
  padding: 0;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.tooltip {
  top: -5.2rem;
  left: 8rem;
  width: 2.69rem;
  text-align: center;
}

.hide {
  display: none;
}
