@value variables: "../../../../../assets/stylesheets/variables.scss";
@value fogColor, steelColor from variables;

.option {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 1.4rem 1.4rem 0;
}

.name {
  width: max-content;
  font-size: 1rem;
  color: fogColor;
  margin-bottom: .4rem;

  &.selected {
    color: white;
  }
}

.button {
  background: none;
  border-radius: 8px;
  border: solid 1px steelColor;
  position: relative;

  &.selected {
    border: solid 1px white;
  }
}

.button:hover {
  border: solid 1px white;
}
