@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.button {
  margin: 0;
}

@media screen and (max-width: smallWidth) {
  .button {
    font-size: 1.4rem;
    padding: .7rem 1rem;
    width: max-content;
  }
}
