@value variables: "../../../../../assets/stylesheets/variables.scss";
@value smallWidth, mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.text {
  color: white;
  font-size: 1.4rem;
  margin-right: 1.4rem;
}

.done {
  margin: 0;
}

@media screen and (max-width: smallWidth) {
  .container {
    justify-content: space-between;
  }

  .done {
    width: unset;
  }
}

@media screen and (max-width: mobileThreshold) {
  .done {
    width: 50%;
  }
}
