@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, midnightColor, crimsonColor, fogColor, steelColor from variables;

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
}

.inputLabel {
  font-size: 1.3rem;
  margin-bottom: .8rem;
  color: midnightColor;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input {
  border: solid 1px fogColor;
  color: slateColor;
  background-color: white;
  width: 100%;
  height: 5.6rem;
  padding: 1.8rem 1.5rem;
  font-size: 1.6rem;
  border-radius: 4px;
  transition: all .2s ease;
}

.input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: steelColor;
  opacity: 1; /* Firefox */
}

.input::-ms-input-placeholder { /* Microsoft Edge */
  color: steelColor;
}

.input:focus {
  border-radius: 4px;
  border: solid 1px midnightColor;
}

.inputError {
  border: solid 1px crimsonColor;
}

.error {
  font-size: 1.2rem;
  color: crimsonColor;
  margin-top: .4rem;
}

.textAlignRight {
  text-align: right;
}

.inputBox {
  height: 8.8rem;
}
