@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor from variables;

.combinations {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
}

.title {
  font-size: 1.6rem;
  color: white;
  margin-left: 1rem;
}

.goBack {
  font-size: 1.4rem;
  color: seaweedColor;
  border: none;
  background: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
