@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, royalColor from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.endText {
  align-self: center;
  font-size: 1.2rem;
  font-style: italic;
  color: midnightColor;
  margin: 2rem;
}

.startNew {
  align-self: center;
  background-color: royalColor;
  color: white;
}

.startNew:hover:enabled {
  background-color: royalColor;
}
