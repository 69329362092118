@value variables: "../../assets/stylesheets/variables.scss";
@value facebookColor from variables;

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  background-color: facebookColor;
  text-decoration: none;
  padding: 1.2rem;
  border-radius: 4px;
  margin-bottom: 2rem;

  div {
    width: 1.3rem;
  }
}

.text {
  font-size: 1.4rem;
  color: white;
}
