@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, smallWidth, mobileThreshold from variables;

.form {
  display: flex;
  align-items: flex-start;
  width: 70%;
  margin-right: 1rem;
}

.inputContainer {
  flex: 1;
  margin-bottom: 0;
  max-width: 37rem;
}

.input {
  height: 3.3rem;
}

.saveButton {
  margin: .3rem 0 0 1rem;
}

.buttonCancel {
  composes: saveButton;
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
  padding: 0;
}

.buttonCancel:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: smallWidth) {
  .form {
    width: 100%;
    margin-right: 0;
  }

  .inputContainer {
    max-width: unset;
  }

  .saveButton {
    width: unset;
  }
}

@media screen and (max-width: mobileThreshold) {
  .form {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .inputContainer {
    flex: unset;
    width: 100%;
  }

  .saveButton {
    width: 45%;
    margin: .4rem 0 1rem;
  }
}


