.container {
  display: flex;
  align-items: center;
  width: 8.5rem;
  height: 100%;
  padding-right: 1.5rem;
  position: initial;
}

.fill {
  display: flex;
  align-items: center;
  height: 100%;
}

.paintBucket {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 1.5rem;
}

.paintBucketLine {
  background: white;
  height: .4rem;

  div {
    height: 100%;
  }
}

.colorPicker {
  position: initial;
}
