@value variables: "../../../../assets/stylesheets/variables.scss";
@value titleColorLight, slateColor, borderMediumColor, mediumWidth, mobileThreshold from variables;

.title {
  font-size: 3.2rem;
  line-height: 1.2;
  text-align: center;
  color: titleColorLight;
  margin-bottom: 4.2rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 83.3rem;
  background: white;
  padding: 3rem 4rem;
}

.header {
  font-size: 2.4rem;
  line-height: 1.2;
  color: slateColor;
  margin-bottom: 1.6rem;
  text-align: center;
}

.card {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  border-bottom: solid 1px borderMediumColor;
}

.brandName {
  font-size: 1.6rem;
  line-height: 1.2;
  color: slateColor;
}

.radiobuttons {
  display: flex;
}

.radiobutton {
  margin-left: 3rem;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.nextButton {
  margin-left: 1rem;
}

@media screen and (max-width: mediumWidth) {
  .content {
    width: 90%;
  }

  .card {
    flex-wrap: wrap;
  }

  .brandName {
    width: 100%;
    margin-bottom: 1rem;
  }

  .radiobutton {
    margin-left: 0;
    margin-right: 3rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 95%;
  }
}
