@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  margin: 0;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: slateColor;
  border-left-color: slateColor;
}

.spinnerContainer {
  padding: 1.2rem 1rem;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.button {
  position: absolute;
  right: 2.4rem;
  top: 1.4rem;
  background: none;
  border: none;
  padding: 0;
}

.inputBox {
  height: 8.8rem;
}
