@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, midnightColor, mediumWidth from variables;

.container {
  display: flex;
  align-items: center;
  margin-left: 10rem;
  height: 100%;
}

.tab {
  margin-right: 3.2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.tab:not(.active)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: .2rem;
  bottom: 0;
  left: 0;
  background-color: midnightColor;
  visibility: hidden;
  transform: scaleX(0);
  transition: all .3s ease-in-out;
}

.tab:not(.active):hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.active {
  border-bottom: solid .2rem midnightColor;

  .link {
    color: midnightColor;
  }
}

.link {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: slateColor;
  text-decoration: none;
}

.tab:not(.active) .link {
  margin-top: -.2rem;
}

@media screen and (max-width: mediumWidth) {
  .container {
    margin-left: 2rem;
  }
}
