@value variables: "../../../../assets/stylesheets/variables.scss";
@value concreteColor, fogColor, concreteColor, smallWidth from variables;

.container {
  width: 18vw;
  max-width: 30rem;
  height: 18vw;
  max-height: 30rem;
  box-shadow: 0 0 1.3rem rgba(0, 0, 0, .25);
  background: white;
  padding: 1.1rem;
  border-radius: 4px;
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: concreteColor;
  border-radius: 4px;
}

.lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

$base-color: fogColor;
$shine-color: concreteColor;
$animation-duration: 1.6s;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0, $shine-color 4rem, $base-color 8rem);
  background-size: 300%;
  border-radius: 4.6rem;
}

.firstLine {
  @include background-gradient;
  height: .8rem;
  width: 80%;
  animation: shine-lines $animation-duration infinite linear;
}

.secondLine {
  @include background-gradient;
  height: .8rem;
  width: 60%;
  margin-top: .8rem;
  animation: shine-lines $animation-duration infinite linear;
}

@keyframes shine-lines {
  0% {
    background-position: 210%;
  }

  75%,
  100% {
    background-position: 90%;
  }
}

@media screen and (max-width: smallWidth) {
  .container {
    width: 50vw;
    height: 50vw;
    box-shadow: none;
    margin: auto;
  }
}
