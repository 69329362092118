@value variables: "../../../../assets/stylesheets/variables.scss";
@value borderMediumColor, slateColor, royalColor, crimsonColor, seaweedColor, mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  color: slateColor;
  padding: 1.6rem 0;
  border-bottom: solid 1px borderMediumColor;
  width: 100%;
}

.card {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.name,
.email {
  width: 17.5rem;
}

.accounts {
  width: 20rem;
}

.pill {
  >span {
    color: white;
    padding: .3rem 1rem;
    border-radius: 1.2rem;
    font-size: 1.2rem;
  }

  &.expired {
    > span {
      background-color: crimsonColor;
    }
  }

  &.invite {
    > span {
      background-color: royalColor;
    }
  }
}

.filler {
  width: 8rem;
}

.tooltip {
  top: unset;
  right: unset;
  left: unset;
  transform: none;
}

.pillContainer,
.buttonsContainer {
  display: flex;
  min-width: max-content;
  justify-content: flex-end;
}

.resend {
  padding: 0 1rem;
  border: none;
  background: none;
  font-size: 1.2rem;
  color: seaweedColor;
}
