@value variables: "../../../../assets/stylesheets/variables.scss";
@value fogColor, slateColor, mediumWidth, smallWidth from variables;

.inputContainer {
  display: flex;
  align-items: flex-start;
  width: 50%;
}

.buttonCancel {
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
  padding: 0;
  margin: .4rem 0 .4rem 1rem;
}

.buttonCancel:hover:enabled {
  background: none;
  border: none;
}

.text {
  font-size: 1.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.optionsContainer {
  max-width: 70%;
}

.optionsButton {
  overflow: hidden;
}

.options {
  top: 140%;
}

.option {
  justify-content: space-between;
}

.optionIcon {
  margin-left: .8rem;
  height: 2.4rem;
  width: 2.4rem;
}

.lastOption {
  border-top: 1px solid fogColor;
}

@media screen and (max-width: mediumWidth) {
  .optionsContainer {
    max-width: 60%;
  }
}

@media screen and (max-width: smallWidth) {
  .text {
    font-size: 1.6rem;
  }

  .optionsContainer {
    max-width: 100%;
  }
}
