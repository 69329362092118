@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.header {
  display: flex;
  flex-wrap: wrap;
}

.permalinkContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2.4rem;
  min-width: 0;
}

.permalinkLabel {
  font-size: 1.4rem;
  color: slateColor;
}

.permalink {
  font-size: 2.4rem;
  color: slateColor;
  margin-top: .8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body {
  display: flex;
  flex: 1;
  min-height: 0;
}
