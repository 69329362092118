@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowHover, midnightColor, slateColor, smallWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.headerInfo {
  font-size: 1.8rem;
  color: midnightColor;
  margin-bottom: 3.2rem;
}

.enableSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    font-size: 1.4rem;
    color: slateColor;
  }
}

@media screen and (max-width: smallWidth) {
  .text {
    margin-right: 1rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }
}
