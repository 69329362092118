@value variables: "../../../assets/stylesheets/variables.scss";
@value coalColor, ironColor, smallWidth, mobileThreshold from variables;

.floatingOptions {
  position: absolute;
  left: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 7rem;
  transition: all .3s ease-in-out;

  // z-index has to be higher than olark
  z-index: 101;

  &.visible {
    top: calc(100% - 14rem);
  }
}

.container {
  height: 14rem;
  width: 100%;
  background-color: ironColor;
  position: relative;
}

.bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: coalColor;
}

.droppableContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.button {
  background: none;
  border: none;
}

.handle {
  position: absolute;
  bottom: calc(100% - 2px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: calc(50% - 3rem);
  background-color: coalColor;
  width: 6rem;
  display: flex;
  justify-content: center;
  border: none;

  .arrowIcon {
    width: 2.4rem;
    height: 2.4rem;
    transition-duration: 200ms;
  }

  .closedIcon {
    transform: rotate(180deg);
  }
}

.slider {
  padding-left: 15rem;
  position: relative;
}

.leftArrow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

@media screen and (max-width: smallWidth) {
  .floatingOptions {
    &.visible {
      top: calc(100% - 12rem);
    }
  }

  .container {
    height: 12rem;
  }

  .slider {
    padding-left: 12.5rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .floatingOptions {
    padding-left: 0;
  }
}
