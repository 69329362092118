.container {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  img {
    width: 2.4rem;
  }
}

.input {
  border: none;
  background: none;
  width: 5rem;
  font-weight: 300;
  color: white;
  font-size: 1.4rem;
  margin-left: .8rem;
}
