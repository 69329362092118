@value variables: "../assets/stylesheets/variables.scss";
@value slateColor, concreteColor from variables;

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: concreteColor;
}

.image {
  max-width: 94.5rem;
  width: 95%;
}

.text {
  font-size: 2.4rem;
  color: slateColor;
  margin-top: 4rem;
}

.feedbackButton {
  width: 17rem;
}
