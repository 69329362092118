@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor from variables;

.textContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.text {
  font-size: 1.4rem;
  color: slateColor;
  padding: 0 1rem;
}

.line {
  border-color: concreteColor;
  flex: 1;
}
