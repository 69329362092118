@value variables: "../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  width: 80%;
  height: 50vh;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.error {
  color: white;
  padding: 10rem 0;
  font-size: 1.4rem;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.loadingIcon {
  width: 50%;
}

.loadingText {
  font-size: 1.4rem;
}

@media screen and (max-width: smallWidth) {
  .container {
    align-items: center;
    margin-bottom: 2rem;
  }
}
