@value variables: "../../../assets/stylesheets/variables.scss";
@value midnightColor from variables;

.emptyContainer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 3.3rem;
}

.emptyTitle {
  font-size: 3.2rem;
  font-weight: 300;
  color: midnightColor;
}

.emptySubtitle {
  padding-top: 1.6rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  color: midnightColor;
}
