@value variables: "../../../../assets/stylesheets/variables.scss";
@value fogColor, midnightColor, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  width: 38.3rem;
  height: 50.2rem;
  box-shadow: 0 .2rem 1.4rem 0 rgba(0, 0, 0, .1);
  background-color: white;
}

.header {
  padding: 2.6rem 3.1rem 0;
}

.title {
  display: flex;
  font-size: 1.8rem;
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  color: midnightColor;
}

.subtitle {
  display: flex;
  height: 7.4rem;
  font-size: 1.4rem;
  color: midnightColor;
}

.body {
  flex: 1;
  border-bottom: 1px solid fogColor;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  height: 6.3rem;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonGetStarted {
  margin-top: 0;
}

.buttonNext {
  width: 9.6rem;
}

.close {
  right: 1.1rem;
}

.buttonArrow {
  background: none;
  border: none;
  padding: 0;
}

@media screen and (max-width: mobileThreshold) {
  .modalContent {
    width: 90%;
  }

  .content {
    width: 100%;
  }
}
