@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  padding: 1.4rem 2.4rem;
  color: white;
  border-top: 1px solid slateColor;
}

.header {
  position: absolute;
  left: 0;
  top: .4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeButton {
  background: none;
  border: none;
  padding: 0;
  margin-right: .8rem;
}

.title {
  font-size: 1.6rem;
  color: white;
  margin-left: 2.4rem;
}

.layouts {
  display: flex;
  flex-wrap: wrap;
}

.layout {
  display: flex;
  flex-direction: column;
}

.optionTitle {
  width: 100%;
  font-size: 1.4rem;
  color: fogColor;
  margin: 1.4rem 0;
}

.options {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    border-top: none;
    flex-direction: row;
    padding: .8rem 2.4rem;
  }

  .layouts {
    flex-wrap: nowrap;
    margin-left: 2.4rem;
    margin-top: 3.2rem;
  }

  .options {
    flex-wrap: nowrap;
  }

  .optionTitle {
    margin: 0 0 1.4rem;
  }
}
