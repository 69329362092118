@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, mintColor, fogColor, mobileThreshold, mobileLowestHeight from variables;

.container {
  width: 61.8rem;
  height: 71.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  max-height: 100vh;
}

.header {
  background: url('../../../assets/images/blue-header-with-white-circles.svg');
  width: 100%;
  background-size: cover;
  height: 15.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 36.7rem;
}

.headerFirstLine {
  color: mintColor;
  font-size: 1.4rem;
}

.headerTitle {
  color: white;
  font-size: 2.4rem;
  text-align: center;
  font-weight: 300;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
}

.secondTitle {
  max-width: 44rem;
  font-size: 1.8rem;
  text-align: center;
}

.featureGrid {
  max-width: 44rem;
}

.bottomLine {
  border-bottom: 1px solid fogColor;
}

.row {
  display: flex;
  min-height: 5.6rem;
  padding: 2rem 0;
}

.featureAvailable {
  min-width: 4.6rem;
  padding-top: .3rem;
  text-align: right;
  padding-right: .9rem;

  .soon {
    background-color: mintColor;
    padding: .2rem .5rem .3rem;
  }
}

.featureName {
  font-size: 1.8rem;
  max-width: 6rem;
  font-weight: 600;
  margin-right: 4.4rem;
}

.featureDesc {
  font-size: 1.6rem;
  color: slateColor;
  max-width: 29rem;
}

.actions {
  width: 19rem;
  text-align: center;

  .actionButton {
    margin-top: 0;
  }
}

.dismissButton,
.dismissButton:hover:enabled {
  background: none;
  border: none;
  font-size: 1.2rem;
  padding-top: 0;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 90vw;
    height: 100%;
  }

  .headerTitle {
    font-size: 1.6rem;
    padding: 0 4.5rem;
  }

  .content {
    padding: 2rem;
  }

  .secondTitle {
    font-size: 1.6rem;
    padding: 0 2rem;
  }

  .row {
    flex-wrap: wrap;
  }

  .row:last-child {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .featureAvailable {
      padding-left: unset;
      min-width: unset;
    }

    .featureName {
      padding-left: 4.2rem;
    }
  }

  .featureAvailable {
    padding-left: 2rem;
  }

  .featureName {
    font-size: 1.6rem;
    margin-right: .8rem;
    max-width: unset;
  }

  .featureDesc {
    font-size: 1.4rem;
    padding: .5rem 3.4rem 0 4.3rem;
    max-width: unset;
  }

  .actions {
    width: unset;
    text-align: center;
  }
}

@media screen and (max-height: mobileLowestHeight) {
  .row {
    flex-wrap: wrap;
    padding: 1rem 0;
  }

  .actions {
    padding: 2rem 0 1rem;
  }
}
