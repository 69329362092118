@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, crimsonColor, smallWidth, mobileThreshold from variables;

.container {
  margin-top: 2rem;
  display: flex;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  width: 44rem;
}

.buttonsContainer {
  display: flex;
  align-self: flex-end;
}

.saveButton {
  margin: .3rem 0 0;
}

.cancelButton {
  composes: saveButton;
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
}

.cancelButton:hover:enabled {
  background: none;
  border: none;
}

.error {
  color: crimsonColor;
  margin-top: .4rem;
  font-size: 1.2rem;
}

@media screen and (max-width: smallWidth) {
  .container {
    flex-direction: column;
  }

  .selectWrapper {
    width: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .selectWrapper {
    width: 30rem;
  }
}
