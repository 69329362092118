@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, midnightColor, royalColor, smallWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.subnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8.4rem;
  background-color: white;
  padding: 0 4.2rem;
}

.title {
  font-size: 1.8rem;
  color: black;
  text-align: left;
  margin: 0;
  font-weight: 400;
}

.searchContainer {
  display: flex;
  align-items: center;
}

.content {
  background-color: concreteColor;
  padding: 4.2rem 4.6rem;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 14.4rem);
  max-height: calc(100vh - 14.4rem);
  min-height: 75rem;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.alertButton {
  margin: 0 0 0 1.6rem;
  font-size: 1.2rem;
  background-color: transparent;
  font-weight: 600;
  padding: 0;
}

.alertButton:hover {
  background-color: transparent !important;
}

.preview {
  color: white;
  background: royalColor;
}

.containerInfo {
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}

@media screen and (max-width: smallWidth) {
  .content {
    height: unset;
    max-height: unset;
    min-height: unset;
  }

  .alertButton {
    width: max-content;
    margin: 1rem 1rem 0 0;
  }

  .upgradeAlertText {
    width: 100%;
    margin-bottom: 1rem;
  }

  .containerInfo {
    flex-direction: column;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    padding: 0 2rem 2rem;
  }

  .subnav {
    padding: 0 2rem;
  }

  .alertButton {
    margin: 0;
  }
}
