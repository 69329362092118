@value variables: "../../../../../assets/stylesheets/variables.scss";
@value steelColor, slateColor, shadowSmall, fogColor, seaweedColor, mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.button {
  height: 4.8rem;
  min-width: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: 4px;
  border: dashed 1px steelColor;
  margin: .5rem .8rem .5rem 0;
  padding: 0;
}

.add {
  color: slateColor;
  font-size: 3.4rem;
  font-weight: 200;
  height: 100%;
}

.options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 5.3rem;
  border-radius: 4px;
  box-shadow: shadowSmall;
  margin: 1rem 0;
}

.name {
  width: 12rem;
  height: 75%;
  font-size: 1.4rem;
  color: slateColor;
  border: none;
  border-right: solid 1px fogColor;
  padding: 0 1.6rem;
  font-size: 1.4rem;
}

.letterSpacing,
.lineHeight {
  color: slateColor;
  width: 3rem;
}

.colorPicker {
  margin-left: 1.6rem;
}

.close {
  border: none;
  background: none;
  height: 1.6rem;
  width: 1.6rem;
  padding: 0;
  align-self: flex-start;
  margin: .8rem .8rem 0 1.6rem;

  img {
    height: 100%;
  }
}

.save {
  margin: .8rem 0 0;
  border: none;
  background: none;
  color: seaweedColor;
}

.spinner {
  border-top-color: borderMediumColor;
  border-left-color: borderMediumColor;
  border-width: 3px;
}

.emptyContainer {
  width: 34rem;
  position: absolute;
  top: 0;
  left: calc(50% - 17rem);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyTitle {
  font-size: 1.4rem;
  color: slateColor;
  margin-top: 3.2rem;
}

.emptyText {
  font-size: 1.2rem;
  color: slateColor;
  margin-top: 1rem;
  text-align: center;
}

@media screen and (max-width: mobileThreshold) {
  .emptyContainer {
    width: 24rem;
    left: calc(50% - 12rem);
  }
}
