@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, templateCardSmall, templateCardSmallInMobile, toolbarControlsWidth, smallWidth, mobileThreshold from variables;

.content {
  width: toolbarControlsWidth;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  border: 1px solid slateColor;
  border-left: 0;
  padding: 4.8rem 2.4rem 2rem;
  position: relative;
}

.closeButton {
  background: none;
  border: none;
  position: absolute;
  right: .8rem;
  top: .8rem;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.6rem;
  color: slateColor;
  margin-bottom: .8rem;
}

.searchContainer {
  margin: .8rem 0 2.4rem;
  width: 100%;
  min-width: unset;
}

.searchInput {
  height: 5.6rem;
  padding: 1.8rem 1.5rem;
  font-size: 1.4rem;
}

.card {
  height: templateCardSmall;
  width: templateCardSmall;
}

@media screen and (max-width: smallWidth) {
  .content {
    padding: 3rem 2.4rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 100vw;
    border: none;
  }

  .card {
    height: templateCardSmallInMobile;
    width: templateCardSmallInMobile;
  }
}
