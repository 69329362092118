@value variables: "../../../../assets/stylesheets/variables.scss";
@value mobileThreshold from variables;

.container {
  display: flex;
  margin-right: 1.4rem;
}

.button {
  width: 3.2rem;
  height: 3.5rem;
  border: none;
  background: none;
  padding: 0;

  img {
    margin-bottom: -.7rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    margin-right: .8rem;
  }
}
