.container {
  position: relative;
}

.input {
  padding: 1.8rem 4.6rem 1.8rem 1.5rem;
}

.icon {
  position: absolute;
  top: 3.8rem;
  right: 1.6rem;
}
