@value variables: "../../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  margin-bottom: 2rem;
  min-height: 17rem;
}

@media screen and (max-width: smallWidth) {
  .container {
    height: unset;
    min-height: unset;
  }
}
