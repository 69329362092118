@value variables: "../../../../../assets/stylesheets/variables.scss";
@value steelColor from variables;

.button {
  border: none;
  background: none;
  padding: 0;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
}

.button:disabled {
  color: steelColor;
}
