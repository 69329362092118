@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, midnightColor, smallWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.subnav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 8.4rem;
  background-color: white;
  padding: 0 4.2rem;
}

.title {
  font-size: 1.8rem;
  color: black;
  text-align: left;
  margin: 0;
  font-weight: 400;
}

.content {
  background-color: concreteColor;
  padding: 4.2rem 4.6rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 14.4rem);
  max-height: calc(100vh - 14.4rem);
  min-height: 75rem;
}

.tabs {
  display: flex;
  align-items: flex-end;
  height: 3rem;
}

.headerOption {
  margin-left: 3rem;
  background: none;
  border: none;
  font-size: 1.4rem;
  color: slateColor;
  padding: .4rem 1.6rem;
}

.active {
  background: midnightColor;
  color: white;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.alertButton {
  margin: 0 0 0 1.6rem;
  font-size: 1.2rem;
  letter-spacing: .38px;
  background-color: transparent;
  font-weight: 600;
  padding: 0;
}

.alertButton:hover {
  background-color: transparent !important;
}

@media screen and (max-width: smallWidth) {
  .content {
    height: unset;
    max-height: unset;
    min-height: unset;
  }

  .alertButton {
    width: max-content;
    margin: 1rem 1rem 0 0;
  }

  .upgradeAlertText {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    padding: 2.4rem 2rem 2rem;
  }

  .subnav {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2rem;
  }

  .headerOption:first-of-type {
    margin-left: 0;
  }

  .tabs {
    margin-top: 1rem;
  }

  .alertButton {
    margin: 0;
  }
}
