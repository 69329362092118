@value variables: "../../../../assets/stylesheets/variables.scss";
@value fadeInOpacity, concreteColor, ironColor from variables;

.helpContainer {
  position: relative;
  background-color: ironColor;
  border-radius: 4px;
  margin-left: .8rem;
}

.button {
  padding: .1rem 0;
}

.buttonHelp {
  border: none;
  width: 100%;
  padding: .8rem 1.6rem;
  font-size: 1.4rem;
  font-weight: 300;
  text-align: left;
  background: white;
}

.buttonHelp:hover {
  background: concreteColor;
}

.helpOptions {
  position: absolute;
  background: white;
  bottom: calc(100% + .2rem);
  right: 0;
  z-index: 3;
  border-radius: 4px;
  padding: 1rem 0;
  width: 15.3rem;
  box-shadow: 0 1px 32px 0 rgba(0, 0, 0, .1);
  animation: fadeInOpacity .25s linear;
}

.linkHelp {
  composes: buttonHelp;
  display: flex;
  text-decoration: none;
  color: black;
}

.tooltip {
  bottom: 115%;
  top: unset;
}
