.container {
  height: 11rem;
  min-height: 11rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 8rem 2rem 0;
  position: relative;
}

.resizeTip {
  position: absolute;
  bottom: 3rem;
  left: calc(50% - 15rem);

  // z-index has to be higher than controls options
  z-index: 104;
}
