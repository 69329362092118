.container {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}

.active {
  border: 1px solid black;
}
