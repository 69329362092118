@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, shadowSmall, mobileThreshold from variables;

.container {
  width: 9.6rem;
  display: flex;
  flex-direction: column;
  margin: 0 2.4rem 2rem 0;
  position: relative;
  outline: none;
  color: slateColor;
  font-size: 1.2rem;

  .name {
    padding-top: .4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  img {
    box-shadow: shadowSmall;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
}

.delete {
  background: slateColor;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: .3rem;
  top: .3rem;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.assetButton {
  padding: 0;
  border: unset;
  background: unset;
  height: 10rem;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 7rem;
  }

  .assetButton {
    height: 8rem;
  }
}
