@value variables: "../../../../assets/stylesheets/variables.scss";
@value concreteColor, royalColor, slateColor, coalColor, toolbarControlsWidth, mobileThreshold from variables;

.container {
  width: toolbarControlsWidth;
  min-height: 100%;
  background-color: concreteColor;
  display: flex;
  flex-direction: column;
  border: 1px solid slateColor;
  border-left: 0;
}

.sourceContainer {
  width: 100%;
  height: 5.4rem;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 1.6rem 0;
}

.source {
  padding-bottom: .5rem;

  img {
    width: 3.6rem;
  }
}

.source:not(.active)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: .2rem;
  bottom: 0;
  left: 0;
  background-color: coalColor;
  visibility: hidden;
  transform: scaleX(0);
  transition: all .3s ease-in-out;
}

.source:not(.active):hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.active {
  border-bottom: solid .2rem royalColor;
}

.content {
  position: relative;
  height: calc(100% - 5.4rem);
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.closeButton {
  background: none;
  border: none;
  position: absolute;
  right: .8rem;
  top: .8rem;
  padding: 0;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 100vw;
    border: none;
  }
}
