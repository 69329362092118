@value variables: "../../../assets/stylesheets/variables.scss";
@value midnightColor, crimsonColor from variables;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.error {
  font-size: 1rem;
  color: crimsonColor;
  margin-top: .8rem;
}
