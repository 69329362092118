@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  width: 18vw;
  max-width: 30rem;
  background: none;
  border: none;
  padding: 0;
  box-shadow: 0 0 1.3rem rgba(0, 0, 0, .25);
}

.thumbnail {
  width: 100%;
  border-radius: 4px;
}

@media screen and (max-width: smallWidth) {
  .container {
    width: unset;
    padding: 0 2rem;
    box-shadow: none;
  }
}
