@value variables: "../../../../../../assets/stylesheets/variables.scss";
@value midnightColor, templateCardSmall, templateCardSmallInMobile, smallWidth, mobileThreshold from variables;

.title {
  font-size: 1.8rem;
  color: midnightColor;
  margin-top: .8rem;
}

.card {
  height: templateCardSmall;
  width: templateCardSmall;
}

@media screen and (max-width: smallWidth) {
  .title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .card {
    height: templateCardSmallInMobile;
    width: templateCardSmallInMobile;
  }
}
