@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor from variables;

.page {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 0;
}

.slideContainer {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: .8rem;

  video, img {
    width: 100%;
  }
}

.slideContainer:last-of-type {
  margin-bottom: .8rem;
}

.downloadButton {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 4px;
  background: rgba(49, 52, 55, .75);

  img {
    width: 80%;
  }
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  text-align: center;
  margin: 2.4rem;
}

.spinnerContainer {
  height: 8rem;
}

.spinner {
  margin-left: -3rem;
  margin-top: 0;
  top: 1rem;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}
