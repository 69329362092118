@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor from variables;

.subtitle {
  font-size: 1.6rem;
  line-height: 1.6;
  color: slateColor;
}

.select {
  width: 100%;
  margin: 1.4rem 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button {
  margin-top: 0;
  width: 100%;
}

.buttonDelete {
  background: none;
  border: none;
  color: seaweedColor;
  padding: 0;
  margin-top: 0;
}

.buttonDelete:hover:enabled {
  background: none;
  border: none;
}
