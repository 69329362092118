@value variables: "../../assets/stylesheets/variables.scss";
@value royalColor from variables;

.circularChart {
  display: block;
}

.circleBg {
  fill: none;
  stroke: royalColor;
  opacity: .2;
  stroke-width: 3;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
}

.animation {
  animation: progress 2s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
