@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.title {
  font-size: 1.8rem;
  color: slateColor;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.4rem;
  color: slateColor;
  text-align: center;
  margin-top: 1.6rem;
  max-width: 57rem;
}

.button {
  margin-top: 3.2rem;
}
