@value variables: "../../../../assets/stylesheets/variables.scss";
@value fogColor, royalColor, slateColor, seaweedColor, mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  color: slateColor;
  padding: 1.6rem 0;
  border-bottom: solid 1px fogColor;
}

.card {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.brandName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.default {
  color: white;
  background: royalColor;
  margin: 0 1rem;
  padding: .3rem 1rem;
  border-radius: 1.2rem;
  font-size: 1.2rem;
}

.buttonsContainer {
  display: flex;
  min-width: max-content;
}

.edit {
  padding: 0;
  border: none;
  background: none;
  font-size: 1.2rem;
  color: seaweedColor;
}

.setDefault {
  composes: edit;
  margin: 0 0 0 1rem;
  border-left: solid 1px seaweedColor;
  border-radius: 0;
  padding-left: 1rem;
  min-height: unset;
  width: unset;
}

.setDefault:hover:enabled {
  background: none;
}

.spinnerIcon {
  border-top-color: seaweedColor;
  border-left-color: seaweedColor;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttonsContainer {
    width: 100%;
    margin-top: 1rem;
  }
}
