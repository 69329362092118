@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 2.4rem;
  min-height: 0;
}

.title {
  font-size: 1.6rem;
  color: slateColor;
  margin-bottom: .8rem;
}

.subtitle {
  font-size: 1.4rem;
  color: slateColor;
}

.dropzoneContainer {
  display: flex;
  margin-top: 2.4rem;
}

.dropzone {
  width: 100%;
}
