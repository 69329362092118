@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, slateColor, mediumWidth, smallWidth from variables;

.container {
  width: 100%;
  height: 17rem;
  border: dashed 1px slateColor;
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  background: white;
}

.dropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 3.2rem;
    margin-bottom: 1rem;
  }
}

.mainText {
  font-size: 1.4rem;
  color: slateColor;
}

.secondaryText {
  font-size: 1.4rem;
  color: slateColor;
  margin: 1rem 0;
}

.button {
  margin: 0;
}

@media screen and (max-width: mediumWidth) {
  .container {
    width: 96%;
  }
}

@media screen and (max-width: smallWidth) {
  .container {
    width: 100%;
    height: 100%;
    max-width: 64rem;
    max-height: 33rem;
    border: none;
  }

  .mainText {
    font-size: 1.2rem;
  }
}
