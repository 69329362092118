@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor from variables;

.container {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  letter-spacing: .5px;
  color: seaweedColor;
  border: none;
  background: none;
}

.input {
  display: none;
}
