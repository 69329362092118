.inputContainer {
  margin-bottom: 0;
}

.input {
  font-size: 1.5rem;
  height: 3rem;
  min-width: 15rem;
  padding: 0 .8rem;
}
