@value variables: "../../../../../../assets/stylesheets/variables.scss";
@value midnightColor, mobileThreshold from variables;

.button {
  padding: 0;
  border: none;
  background: none;
  border-radius: 8px;
  transition: background-color 120ms linear;

  img {
    width: 10rem;
  }
}

.button:hover {
  background-color: midnightColor;
}

@media screen and (max-width: mobileThreshold) {
  .button img {
    width: 6rem;
  }
}
