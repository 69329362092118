@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor, fogColor, iceColor, concreteColor, titleColorLight, slateColor, extraLargeWidth, largeWidth, mediumWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  flex: 1;
  padding: 0 4.6rem 4.2rem;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 4.2rem 0 0;
}

.title {
  font-size: 3.2rem;
  font-weight: 300;
  color: titleColorLight;
  letter-spacing: 1.5px;
  margin: 0;
}

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.4rem 0;
  width: 100%;
}

.planSwitch {
  display: flex;
  align-items: center;

  span {
    font-size: 1.4rem;
    color: slateColor;
    margin: 0 1rem;
  }
}

.opacity {
  opacity: .5;
}

.casualTop {
  border: solid 8px fogColor;
  background-color: fogColor;
}

.coreTop {
  border: solid 8px black;
  background-color: black;
}

.performanceTop {
  border: solid 8px iceColor;
  background-color: iceColor;
}

@media screen and (max-width: extraLargeWidth) {
  .plans {
    margin: 1.4rem 0;
  }
}

@media screen and (max-width: largeWidth) {
  .plans {
    flex-wrap: wrap;
  }
}


@media screen and (max-width: mediumWidth) {
  .header {
    margin: 2.4rem 0 0;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    padding: 4.2rem 2rem;
  }

  .header {
    margin: 0 0 1rem;
  }

  .title {
    font-size: 1.8rem;
  }
}
