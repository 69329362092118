.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// stylelint-disable

.slider :global(.slick-slide) {
  opacity: .3 !important;
}

.slider :global(.slick-current) {
  opacity: 1 !important;
}

.slider :global(.slick-slider) {
  -webkit-touch-callout: unset;
}

// stylelint-enable
