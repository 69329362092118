@value variables: "../../../assets/stylesheets/variables.scss";
@value seaweedColor, emeraldColor, shadowSmall, mobileThreshold from variables;

.container {
  margin: 0 0 0 2.6rem;
  align-items: center;
}

.options {
  box-shadow: shadowSmall;
  width: 100%;
  top: 90%;
}

.option {
  text-align: left;
}

.button {
  background: seaweedColor;
  height: 4.8rem;
  padding: 0 1.2rem;
  border-radius: 4px;
}

.button:hover:enabled {
  background-color: emeraldColor;
}

.spinnerContainer {
  width: 100%;
}

.text {
  color: white;
  font-size: 1.6rem;
  margin-right: 1rem;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    margin: 1.4rem 0;
  }

  .button {
    padding: 0 .8rem 0 1.2rem;
  }

  .text {
    font-size: 1.4rem;
    margin-right: .4rem;
  }

  .options {
    top: 115%;
  }
}
