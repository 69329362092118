@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 3.2rem 4.8rem;
}

.question {
  font-size: 1.8rem;
  color: slateColor;
  margin-bottom: 1.6rem;
}

.input {
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.4rem;
}

.actionButton {
  margin-top: 0;
  width: 100%;
}

.dismissButton,
.dismissButton:hover:enabled {
  background: none;
  border: none;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  align-self: center;
  color: slateColor;
}
