@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, concreteColor, crimsonColor, shadowRegular, crismonFadedColor, slateColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 38rem;
  padding: 4rem;
  background: concreteColor;
  box-shadow: shadowRegular;
  animation: fadeInOpacity .2s linear;
}

.title {
  font-size: 2.4rem;
  color: midnightColor;
  margin-bottom: .8rem;
}

.errorContainer {
  width: 100%;
  margin-top: .8rem;
  padding: 1.4rem .8rem;
  background-color: crismonFadedColor;
}

.errorText {
  font-size: 1.2rem;
  color: crimsonColor;
}

.website {
  font-size: 1.2rem;
  font-weight: 300;
  color: slateColor;
  padding: 2rem 0 .8rem;
}

.inputContainer {
  margin: 0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 49%;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 100%;
  }

  .modal {
    width: 90%;
  }
}
