@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor, crimsonColor, concreteColor, coalColor, scrollbars, smallWidth from variables;

@import '../../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 2rem 2.4rem;
  overflow: hidden;
}

.title {
  font-size: 1.6rem;
  color: slateColor;
  margin-bottom: .8rem;
}

.subtitle {
  font-size: 1.4rem;
  color: slateColor;
}

.inputContainer {
  width: 100%;
  margin: 2.4rem 0;
}

.input {
  padding: 1.8rem 3.6rem 1.8rem 1.5rem;
  font-size: 1.4rem;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: slateColor;
  border-left-color: slateColor;
}

.spinnerContainer {
  padding: 1.2rem 1rem;
}

.button {
  position: absolute;
  right: 1.4rem;
  top: 1.4rem;
  background: white;
  border: none;
  padding: 0;
}

.results {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 8.2rem;

  @include scrollbars(concreteColor, coalColor);
}

.error {
  font-size: 1.2rem;
  color: crimsonColor;
  width: 100%;
}

.noResults {
  font-size: 1.2rem;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, transparent, concreteColor 30%);
  position: absolute;
  bottom: 2rem;
  left: 0;
  padding: 0 2.4rem;
}

.import {
  width: 100%;
  margin-top: 1rem;
}

.fullscreenImageContainer {
  margin: 0 !important;
}

.fullscreenImageButton {
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
}

.fullscreenImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: smallWidth) {
  .container {
    height: calc(100% - 7.4rem);
  }

  .search {
    margin: 2rem 0;
  }
}
