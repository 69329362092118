@value variables: "../../../../assets/stylesheets/variables.scss";
@value royalColor from variables;

.container {
  height: 10rem;
  margin: 0 .8rem;
}

.selected {
  border: 4px solid royalColor;
}

.image {
  height: 100%;
}


