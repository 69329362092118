@value variables: "../../../assets/stylesheets/variables.scss";
@value titleColorLight, slateColor, borderMediumColor, mediumWidth, mobileThreshold from variables;

.title {
  font-size: 3.2rem;
  line-height: 1.2;
  text-align: center;
  color: titleColorLight;
  margin-bottom: 4.2rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 83.3rem;
  background: white;
  padding: 3rem 4rem;
}

.header {
  font-size: 2.4rem;
  line-height: 1.2;
  color: slateColor;
  margin-bottom: 1.6rem;
}

.card {
  padding: 1.6rem;
  border-bottom: solid 1px borderMediumColor;
}

.checkbox {
  font-size: 1.6rem;
}

.delete {
  align-self: flex-end;
}

@media screen and (max-width: mediumWidth) {
  .content {
    width: 90%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 95%;
  }
}
