@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, shadowSmall, mobileThreshold from variables;

.color {
  min-width: 9.6rem;
  height: 9.6rem;
  border-radius: 4px;
  box-shadow: shadowSmall;
  margin: 0 2.4rem 2rem 0;
}

.colorPickerContainer {
  height: 100%;
}

.swatchContainer {
  height: 100%;
}

.swatch {
  border: none;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 0;
}

.swatchColor {
  flex: 1;
  width: 100%;
  min-width: 9.6rem;
  border-radius: 4px 4px 0 0;
}

.swatchName {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 4px 4px;
}

.delete {
  background: slateColor;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: .3rem;
  top: .3rem;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.customPosition {
  position: relative;
}

@media screen and (max-width: mobileThreshold) {
  .color {
    min-width: 7rem;
    height: 7rem;
  }

  .swatchColor {
    min-width: 7rem;
  }

  .swatchName {
    height: 2.7rem;
    font-size: 1.2rem;
  }
}
