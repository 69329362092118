@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, crimsonColor, smallWidth, mobileThreshold from variables;

.container {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  margin-bottom: 0;
  width: 30rem;
}

.buttonsContainer {
  display: flex;
}

.saveButton {
  margin: .3rem 0 0;
}

.cancelButton {
  composes: saveButton;
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
}

.cancelButton:hover:enabled {
  background: none;
  border: none;
}

.error {
  color: crimsonColor;
  margin-top: .8rem;
}

@media screen and (max-width: smallWidth) {
  .input {
    width: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    flex-direction: column;
  }

  .buttonsContainer {
    width: 100%;
    margin-top: 1rem;
  }
}
