@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.title {
  font-size: 1.8rem;
  text-align: center;
  color: slateColor;
  margin: 2rem 0 1.6rem;
}

.subtitle {
  width: 57rem;
  font-size: 1.4rem;
  line-height: 1.57;
  text-align: center;
  color: slateColor;
}

.button {
  margin-top: 3.2rem;
}
