@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor from variables;

.container {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.preview ul {
  bottom: 4px;
}

.preview button img[src*="white"] {
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7460%) hue-rotate(79deg) brightness(112%) contrast(108%);
}

.preview button img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5473%) hue-rotate(53deg) brightness(118%) contrast(60%);;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.error {
  font-size: 1.4rem;
  color: slateColor;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px slateColor;
}
