@value variables: "../../../../assets/stylesheets/variables.scss";
@value concreteColor, coalColor, shadowSmall, fadeInOpacity from variables;

.container {
  width: 100%;
  height: 5.4rem;
  display: flex;
  justify-content: space-between;
  background-color: coalColor;
  padding: 0 2.4rem;
  position: relative;
}

.collapseContainer {
  position: relative;
  display: flex;
  margin-left: 1.5rem;
  padding-right: 1rem;
}

.collapseButton {
  border: none;
  background: none;
  color: white;
  padding: 0;
  min-width: max-content;
}

.overflowContainer {
  width: 17rem;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
  background-color: coalColor;
  box-shadow: shadowSmall;
  padding: .8rem;
  animation: fadeInOpacity .25s linear;
}

