@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor, steelColor, seaweedColor, emeraldColor, concreteColor, slateColor, smallWidth from variables;

.button {
  width: max-content;
  min-height: 4.8rem;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1.6rem;
  letter-spacing: .5px;
  text-align: center;
  margin-top: 2rem;
  padding: .7rem 2rem;
  transition: background-color 120ms linear;
}

.cta {
  background-color: midnightColor;
}

.cta:hover:enabled {
  background-color: black;
}

.alternativeCTA {
  background-color: seaweedColor;
}

.alternativeCTA:hover:enabled {
  background-color: emeraldColor;
}

.secondary {
  border: 1px solid steelColor;
  background-color: white;
  color: midnightColor;
}

.secondary:hover:enabled {
  background-color: concreteColor;
}

.secondarySpinner {
  border-top-color: slateColor;
  border-left-color: slateColor;
}

.short {
  min-height: 3.3rem;
}

.button:disabled {
  opacity: .5;
}

@media screen and (max-width: smallWidth) {
  .button {
    width: 100%;
  }
}
