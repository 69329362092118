@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.projectList {
  padding: 0 2.4rem 1.6rem;
}

@media screen and (max-width: smallWidth) {
  .projectCardContainer {
    margin-bottom: 1.6rem;
  }
}
