@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor, concreteColor, smallWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  min-height: 100%;
}

.pageContent {
  display: flex;
  flex: 1;
  padding: 4rem 0 6rem;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.athletes {
  width: 72rem;
}

.iconTitle {
  width: 11.5rem;
  margin: 0 auto 2.4rem;
}

.form {
  width: 47rem;
  display: flex;
  flex-direction: column;
  padding: 3.8rem 6.4rem;
  background-color: white;
  border-radius: 8px;
  margin-top: -20rem;
}

.signinContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  span {
    color: slateColor;
    font-size: 1.4rem;
  }
}

.link {
  color: seaweedColor;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: .5rem;
}

@media screen and (max-width: smallWidth) {
  .athletes {
    width: 100%;
  }

  .form {
    margin-top: -30%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .pageContent {
    padding: 2rem 0 6rem;
  }

  .form {
    width: 97%;
    padding: 1.9rem 3.15rem;
  }
}
