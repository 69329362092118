@value variables: "../../assets/stylesheets/variables.scss";
@value ironColor, midnightColor, smallWidth from variables;

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  background-color: ironColor;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: midnightColor;
  padding: 1.6rem 3.2rem;
  margin-bottom: 1.4rem;
}

.name {
  color: white;
  font-size: 1.6rem;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: .4rem;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  color: white;
  font-size: 1.6rem;
  text-align: center;
  padding: 0 1.4rem;
}

@media screen and (max-width: smallWidth) {
  .header {
    padding: 1.6rem;
  }

  .name {
    font-size: 1.2rem;
    width: 100%;
  }
}
