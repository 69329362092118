@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, largeWidth from variables;

.addButton {
  color: slateColor;
  font-size: 1.4rem;
  font-weight: 600;
  border: none;
  background: none;
  padding: .4rem .8rem;
  margin: .8rem 0 2rem;
}

@media screen and (max-width: largeWidth) {
  .addButton {
    padding: .4rem 0;
  }
}
