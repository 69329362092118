@value variables: "../../../assets/stylesheets/variables.scss";
@value modalWidth, coalColor, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: modalWidth;
}

.header {
  background: url('../../../assets/images/blue-header-with-skyblue-circles.svg');
  width: 100%;
  background-size: cover;
  padding: 2rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
}

.title {
  font-size: 2.4rem;
  color: white;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem;
}

.text {
  max-width: 36rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: coalColor;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.4rem;
}

.buttonUpload {
  margin-top: 0;
}

.buttonTour {
  background: none;
  border: none;
  font-size: 1.4rem;
  color: coalColor;
  padding: 0;
  margin-top: 0;
}

.buttonTour:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 100%;
    max-height: 90vh;
  }

  .modal {
    width: 90%;
  }

  .header {
    padding: 3.2rem 3rem;
  }

  .title {
    font-size: 2.2rem;
  }
}
