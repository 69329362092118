@value variables: "../../../assets/stylesheets/variables.scss";
@value midnightColor, smallWidth, mobileThreshold from variables;

.container {
  height: 6.4rem;
  min-height: 6.4rem;
  width: 100%;
  background-color: midnightColor;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  white-space: nowrap;
  padding: 0 2.4rem;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.changesSaved {
  opacity: 0;
  font-size: 1.4rem;
  font-style: italic;
  color: white;
  margin-right: 1.8rem;
  transition: opacity .2s ease-in-out;

  &.show {
    opacity: .5;
  }
}

.back {
  width: 3.2rem;
  height: 3.5rem;
  background: none;
  border: none;
  padding: 0;
}

.export:enabled:hover {
  background-color: emeraldColor !important;
}

.productTour {
  position: absolute;
  top: 30%;
  right: 12rem;
  z-index: 4;
}

@media screen and (max-width: smallWidth) {
  .content {
    width: 40%;
  }

  .buttonExport {
    margin: 0 0 0 .5rem;
    padding: .7rem 1.4rem;
  }

  .productTour {
    top: 38%;
    right: 5rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 0 1.2rem;
  }

  .content {
    width: unset;
  }
}
