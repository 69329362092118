@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.button {
  display: flex;
  align-items: center;
  margin: 3rem auto 0;
}

.text {
  font-size: 1.4rem;
  color: slateColor;
  margin-right: 1rem;
}
