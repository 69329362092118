.wrapper {
  width: 100%;
}

// stylelint-disable

:global(.react-horizontal-scrolling-menu--scroll-container) {
  height: 100% !important;
}

:global(.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar) {
  display: none;
}

// stylelint-enable
