.container {
  display: flex;
  flex-direction: column;
}

.tagButton img {
  padding: .7rem;
}

.deleteButton img {
  padding: .4rem;
}
