@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor, crimsonColor from variables;

.selectLabel {
  display: block;
  font-size: 1.3rem;
  color: midnightColor;
  margin-bottom: 0.8rem;
}

.selectError {
  border: solid 1px crimsonColor;
  border-radius: 4px;
}

.error {
  font-size: 1.2rem;
  color: crimsonColor;
  margin-top: .4rem;
}
