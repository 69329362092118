@value variables: "../../../../../../assets/stylesheets/variables.scss";
@value midnightColor from variables;

.content {
  width: 49%;
  padding: 1rem;
  border: none;
  background: none;
  border-radius: 8px;
  transition: background-color 120ms linear;

  img {
    width: 100%;
  }
}

.content:hover {
  background-color: midnightColor;
}
