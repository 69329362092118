@value variables: "../../../assets/stylesheets/variables.scss";
@value seaweedColor, shadowSmall, slateColor, midnightColor, smallWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.2rem;
}

.header {
  font-size: 1.8rem;
  color: midnightColor;
}

.manage {
  width: 10rem;
  font-size: 1.2rem;
  letter-spacing: .5px;
  color: seaweedColor;
  border: none;
  background: none;
  margin: 0;
  padding: .7rem 0;
}

.manage:hover:enabled {
  background-color: transparent;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: slateColor;
  border-left-color: slateColor;
}

.alert {
  border-radius: 4px;
  margin-bottom: 2rem;
}

.alertButton {
  margin: 0 0 0 1.6rem;
}

.alertText {
  max-width: 78%;
}

@media screen and (max-width: smallWidth) {
  .manage {
    width: max-content;
  }

  .alertButton {
    width: max-content;
  }

  .alertText {
    max-width: unset;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }

  .header {
    width: 100%;
  }

  .manage {
    padding: 1rem 0 0;
  }
}
