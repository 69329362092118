@value variables: "../../../assets/stylesheets/variables.scss";
@value concreteColor, slateColor from variables;

.option {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: .8rem 1.6rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 300;
  color: slateColor;

  &.active {
    background: slateColor;
    color: white;
  }
}

.option:hover:not(.active) {
  background: concreteColor;
}

.linkOption {
  composes: option;
  display: flex;
  text-decoration: none;
  color: slateColor;
}
