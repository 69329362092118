@value variables: "../../../../../assets/stylesheets/variables.scss";
@value mobileThreshold from variables;

.button {
  margin: 0 0 0 2.6rem;
}

@media screen and (max-width: mobileThreshold) {
  .button {
    margin: 0 0 0 1rem;
  }
}
