@value variables: "../../../assets/stylesheets/variables.scss";
@value titleColorLight from variables;

.circle {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.welcomeMsg {
  font-size: 3.2rem;
  font-weight: 300;
  color: titleColorLight;
  text-align: left;
  letter-spacing: 1.5px;
  margin: 1.6rem 0 .8rem;
}

.message {
  margin: 0;
  max-width: 61.8rem;
  line-height: 1.56;
  font-size: 1.8rem;
  text-align: left;
}
