@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, seaweedColor, shadowSmall, extraLargeWidth, largeWidth from variables;

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 5.3rem;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
  margin: 1rem 0;
}

.name {
  width: 12rem;
  height: 75%;
  font-size: 1.4rem;
  color: slateColor;
  border: none;
  border-right: solid 1px fogColor;
  padding: 0 1.6rem;
  font-size: 1.4rem;
}

.letterSpacing,
.lineHeight {
  color: slateColor;
  width: 3rem;
}

.colorPicker {
  margin-left: 1.6rem;
}

.close {
  border: none;
  background: none;
  height: 1.6rem;
  width: 1.6rem;
  padding: 0;
  align-self: flex-start;
  margin: .8rem .8rem 0 1.6rem;

  img {
    height: 100%;
  }
}

.saveContainer {
  display: flex;
  margin: 0;
}

.save {
  border: none;
  background: none;
  color: seaweedColor;
}

.spinner {
  border-top-color: borderMediumColor;
  border-left-color: borderMediumColor;
  border-width: 3px;
}

@media screen and (max-width: extraLargeWidth) {
  .lineHeight {
    width: 4rem;
  }
}

@media screen and (max-width: largeWidth) {
  .container {
    width: 100%;
    margin: 1rem 0 2rem;
    flex-wrap: wrap;
  }

  .saveContainer {
    width: 100%;
  }
}
