@value variables: "../../assets/stylesheets/variables.scss";
@value crimsonColor, midnightColor from variables;

.container {
  display: flex;
  flex-direction: column;
}

.select {
  display: flex;
  flex-direction: column;
}

.selectError {
  border: solid 1px crimsonColor;
  border-radius: 4px;
}

.error {
  font-size: 1.2rem;
  color: crimsonColor;
  margin-top: .4rem;
}

.selectLabel {
  font-size: 1.3rem;
  color: midnightColor;
  margin-bottom: 0.8rem;
}
