@value variables: "../../../assets/stylesheets/variables.scss";
@value midnightColor from variables;

.modal {
  height: 100%;
  width: calc(100% - 12rem);
  top: 0;
  border-radius: 0;
  background-color: midnightColor;
}

.closeModal {
  padding: 0;

  img {
    height: 4rem;
    width: 4rem;
  }
}

.gridItem {
  margin-bottom: 2rem;
}

.masonryContainer {
  padding: 3.3rem 2.4rem 6.4rem;
}
