@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor from variables;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 2.4rem 2.4rem;
  width: 13rem;
}

.label {
  font-size: 1.4rem;
  color: slateColor;
}

.postedDate {
  font-size: 1.4rem;
  color: slateColor;
  font-weight: 300;
  margin-bottom: .3rem;
}

.spinnerContainer {
  height: 100%;
  width: 60%;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}
