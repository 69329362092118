@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  width: 9.6rem;
  height: 9.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .8rem;
  padding: 0 .4rem;
  position: relative;
  outline: none;
  background-color: white;
  border-radius: 4px;
}

.name {
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input {
  min-width: unset;
}

@media screen and (max-width: smallWidth) {
  .container {
    width: 7rem;
    height: 7rem;
  }
}
