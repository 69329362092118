@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowSmall, shadowHover, fogColor, slateColor, midnightColor, mediumWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.enableEditForm {
  box-shadow: shadowHover;
}

.headerInfo {
  font-size: 1.8rem;
  color: midnightColor;
  margin-bottom: 3.2rem;
}

.inputNameContainer {
  display: flex;
  justify-content: space-between;
}

.inputSmallContainer {
  width: 49%;
}

.inputLargeContainer {
  width: 49%;
}

.divider {
  border-top: solid 1px fogColor;
  margin-bottom: 2.3rem;
}

.subtitleInfo {
  font-size: 1.8rem;
  color: slateColor;
  margin-bottom: 2.5rem;
}

.containerSubmit {
  display: flex;
  justify-content: flex-end;
}

.buttonCancel {
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
}

.buttonCancel:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: mediumWidth) {
  .inputLargeContainer {
    width: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }

  .inputNameContainer {
    flex-direction: column;
  }

  .inputSmallContainer {
    width: 100%;
  }
}
