@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, crimsonColor, largeWidth, smallWidth, mobileThreshold from variables;

.container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.fieldsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.input {
  margin-bottom: 0;
  width: 24rem;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  width: 49.6rem;
  margin-top: 1rem;
}

.buttonsContainer {
  display: flex;
  align-self: flex-end;
  margin-top: 2rem;
}

.saveButton {
  margin: .3rem 0 0;
}

.cancelButton {
  composes: saveButton;
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
}

.cancelButton:hover:enabled {
  background: none;
  border: none;
}

.error {
  color: crimsonColor;
  margin-top: .4rem;
  font-size: 1.2rem;
}

@media screen and (max-width: largeWidth) {
  .input {
    width: 33%;
  }

  .selectWrapper {
    width: 100%;
  }
}

@media screen and (max-width: smallWidth) {
  .fieldsContainer {
    flex-direction: column;
  }

  .input {
    width: 100%;
    margin-top: 1rem;
  }

  .selectWrapper {
    width: 100%;
  }

  .buttonsContainer {
    width: 100%;
    margin-top: 1rem;
  }
}
