@value variables: "../../../../../assets/stylesheets/variables.scss";
@value steelColor, slateColor, mobileThreshold from variables;

.colorPickerContainer {
  min-width: 9.6rem;
  height: 9.6rem;
  margin: 0 2.4rem 2rem 0;
}

.addButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 9.6rem;
  height: 9.6rem;
  background: none;
  border-radius: 4px;
  border: dashed 1px steelColor;
}

.add {
  color: slateColor;
  font-size: 3.4rem;
  font-weight: 200;
}

.customPosition {
  position: relative;
}

@media screen and (max-width: mobileThreshold) {
  .colorPickerContainer {
    min-width: 7rem;
    height: 7rem;
  }

  .addButton {
    min-width: 7rem;
    height: 7rem;
  }
}
