@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, shadowSmall, fadeInOpacity from variables;

.container {
  position: relative;
}

.options {
  position: absolute;
  top: 8.5rem;
  right: 0;
  width: 100%;
  max-height: 14rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
  text-align: left;
  padding: .8rem 0;
  overflow: auto;
  z-index: 11;
  animation: fadeInOpacity .3s linear;
}

.option {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: .8rem 1.6rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 300;
  text-align: left;
}

.option:hover {
  background: concreteColor;
}
