@value variables: "../../../../assets/stylesheets/variables.scss";
@value crimsonColor, midnightColor, extraLargeWidth, smallWidth from variables;

.blankcard {
  height: 20vw;
  width: 20vw;
}

.templates {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 3.6rem;
}

.spinnerContainer {
  height: 20vw;
  width: 100%;
}

.spinner {
  margin-top: -3rem;
  margin-left: -3rem;
}

.spinnerIcon {
  width: 4rem;
  height: 4rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.error {
  font-size: 1rem;
  color: crimsonColor;
  margin-top: .8rem;
}

@media screen and (max-width: extraLargeWidth) {
  .blankcard {
    height: 17vw;
    width: 17vw;
  }

  .spinnerContainer {
    height: 17vw;
  }
}

@media screen and (max-width: smallWidth) {
  .blankcard {
    height: 40vw;
    width: 40vw;
  }

  .spinnerContainer {
    height: 40vw;
  }
}

