@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.page {
  display: flex;
  height: 100vh;
}

.leftContainer {
  display: flex;
  flex-shrink: 0;
  width: 47.9rem;
  background: url("./left-container.png");
  background-size: cover;
  background-position: bottom;
}

.rightContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 4.6rem 0 10.9rem;
}

.mainContainer {
  height: calc(100vh - 10rem);
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-right: 15.6rem;
}

.mainContainer form {
  width: 100%;
}

.mainContainer form label {
  color: slateColor;
  font-size: 1.4rem;
}

.actionsContainer {
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.buttonSkip {
  background: none;
  border: none;
  color: slateColor;
  padding-left: 0;
}

.buttonSkip:hover:enabled {
  background: none;
  border: none;
}

.buttonNext {
  width: 12.2rem;
}

.buttonBack {
  border: solid 1px slateColor;
  margin-right: .8rem;
  width: 12.2rem;
}
