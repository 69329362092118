@mixin scrollbars($backgroundColor, $scrollbarColor, $scrollbarWidth: 1.4rem) {
  &::-webkit-scrollbar {
    width: $scrollbarWidth;
    height: $scrollbarWidth;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbarColor;
    border-radius: 8px;
    border: 3px solid $backgroundColor;
  }

  &::-webkit-scrollbar-corner {
    background: $backgroundColor;
  }
}

@mixin assetWidthInAddImageControl($imageDimension) {
  .imageContainer {
    width: $imageDimension;
    height: $imageDimension;
  }

  .image {
    width: $imageDimension;
    height: $imageDimension;
  }

  .name {
    width: $imageDimension;
  }
}
