@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor from variables;

.container {
  padding: .2rem 2rem;
}

h1 {
  color: slateColor;
  margin: 2.4rem;
}

h2 {
  color: slateColor;
  margin-top: 4rem;
}

h3 {
  font-size: 1.4rem;
  color: slateColor;
  margin-top: 4rem;
}

li {
  font-size: 1.4rem;
  color: slateColor;
  margin-top: 1rem;
  text-align: justify;
}

p {
  font-size: 1.4rem;
  color: slateColor;
  margin-top: 4rem;
  text-align: justify;
}

.goBack {
  font-size: 1.2rem;
  color: seaweedColor;
  text-decoration: none;
  margin: .8rem;
  display: flex;
  justify-content: center;
}
