.container {
  border-radius: 5px;
  background-color: white;
  height: 2rem;
  z-index: 2;
  position: relative;
}

.open {
  z-index: 3;
}

.disabled {
  border-radius: 4px;
  height: 2rem;
  width: 2rem;
}

.content {
  height: 100%;
}

.swatch {
  height: 2rem;
  width: 2rem;
  border: white 1px solid;
  border-radius: 4px;
}

.picker {
  position: absolute;
  top: 0;
  z-index: 2;

  &.right {
    left: calc(100% + .3rem);
  }

  &.left {
    right: calc(100% + .3rem);
  }
}

// stylelint-disable

:global(.sketch-picker) {
  box-shadow: rgba(0, 0, 0, .15) 0 8px 16px !important;
}

:global(#rc-editable-input-1) {
  width: 90% !important;
}

// stylelint-enable
