@value variables: "../../../../../assets/stylesheets/variables.scss";
@value concreteColor, coalColor, mobileThreshold from variables;

@import '../../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 40rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 1.5rem;
}

.results {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  overflow-y: auto;
  margin-bottom: 5.2rem;

  @include scrollbars(concreteColor, coalColor);
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, transparent, concreteColor 30%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.addImages {
  width: 100%;
}
