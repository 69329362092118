@value variables: "../../../assets/stylesheets/variables.scss";
@value smallWidth, mobileThreshold from variables;

@media screen and (max-width: smallWidth) {
  .applyTemplate {
    width: max-content;
    margin: 5rem auto;
  }
}

@media screen and (max-width: mobileThreshold) {
  .applyTemplate {
    width: 100%;
  }
}
