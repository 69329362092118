@value variables: "../../../../assets/stylesheets/variables.scss";
@value mediumWidth from variables;

.card {
  width: 9rem;
  height: 9rem;
  border: none;
  background: none;
  padding: 0;
  margin: .3rem;
  transition: all ease-in-out .2s;

  &.active {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
  }
}

.card:hover:not(.active) {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .2);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: mediumWidth) {
  .card {
    width: 11rem;
    height: 11rem;
  }
}
