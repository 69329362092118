@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor, shadowRegular, fadeInOpacity, concreteColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 38rem;
  padding: 4rem;
  background: concreteColor;
  box-shadow: shadowRegular;
  animation: fadeInOpacity .2s linear;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}

.icon {
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1.2rem;
}

.title {
  font-size: 1.8rem;
  line-height: 1.2;
  color: midnightColor;
}

.content {
  display: flex;
  flex-direction: column;
}

.category {
  font-size: 1.2rem;
  line-height: 1.2;
  letter-spacing: .5px;
  margin-bottom: .8rem;
  color: slateColor;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.4rem;
}

.saveButton {
  margin: 0;
  width: 100%;
}

.removeButton,
.removeButton:hover:enabled {
  background: none;
  border: none;
  font-size: 1.4rem;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 100%;
  }

  .modal {
    width: 90%;
  }
}
