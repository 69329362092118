@value variables: "../../../../assets/stylesheets/variables.scss";
@value royalColor, concreteColor, shadowSmall, fadeInOpacityAndScale, fadeOutOpacityAndScale, mobileThreshold from variables;

.productTour {
  position: absolute;
  bottom: 8.5rem;
  left: 4rem;

  // z-index has to be higher than controls options
  z-index: 103;
}

.container {
  position: relative;
  width: 100%;
  display: flex;
}

.icon {
  width: 4.6rem;
  padding: 1rem;
}

.tooltip {
  top: calc(50% - 1.7rem);
  left: 14rem;

  // z-index has to be higher than controls options
  z-index: 1000;
}

.collageTip {
  position: absolute;
  top: 0;
  bottom: 8.5rem;
  left: 4rem;

  // z-index has to be higher than controls options
  z-index: 103;
}

@media screen and (max-width: mobileThreshold) {
  .productTour {
    bottom: 3.5rem;
    left: unset;
    right: 7rem;
  }

  .container {
    height: 100%;
  }
}
