@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, smallWidth, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  width: 75.2rem;
  height: 63.3rem;
  padding: 6.5rem 8rem 4.9rem;
}

.header {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  font-size: 2.4rem;
  color: slateColor;
}

.details {
  width: 100%;
  flex: 1;
  display: flex;
  padding-top: 1.9rem;
  overflow-y: auto;
}

.imgContainer {
  width: 65%;
  padding-right: 4rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}

.subtitle {
  font-size: 1.2rem;
}

.subtitle:not(:first-child) {
  padding-top: 3.2rem;
}

.features {
  width: 35%;

  .tags {
    font-size: 1.4rem;
    color: slateColor;
  }
}

.swatches {
  display: flex;
  flex-wrap: wrap;
}

.swatch {
  display: flex;
  padding-top: .6rem;
  padding-right: 1.6rem;

  span {
    color: slateColor;
    font-size: 1.4rem;
    width: 6rem;
  }
}

.color {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .7rem;
  border: 1px solid fogColor;
}

.actions {
  display: flex;
  justify-content: center;
  padding-top: 3.1rem;
}

.close {
  margin-top: 0;
}

.noDetailsContainer {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.noDetailsTitle {
  font-size: 1.4rem;
  color: slateColor;
  margin-top: 3.2rem;
}

.noDetailsText {
  font-size: 1.2rem;
  color: slateColor;
  margin-top: 1rem;
}

@media screen and (max-width: smallWidth) {
  .content {
    width: 90vw;
    max-height: 100%;
    padding: 4rem;
  }

  .details {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }

  .imgContainer {
    width: 100%;
    padding: 0;
  }

  .subtitle,
  .subtitle:not(:first-child) {
    padding-top: 2rem;
  }

  .actions {
    display: flex;
    justify-content: center;
  }

  .features {
    width: 100%;
  }

  .noDetailsContainer {
    width: 100%;
    padding-top: 3rem;
  }
}
