@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor from variables;

.container {
  padding: .2rem 2rem;
}

.title {
  color: slateColor;
  margin: 2.4rem;
}

.subtitle {
  color: slateColor;
  margin-top: 4rem;
}

.header {
  color: slateColor;
  margin-top: 4rem;
  text-align: justify;
}

.content {
  font-size: 1.4rem;
  color: slateColor;
  margin-top: 4rem;
  text-align: justify;
}

.goBack {
  font-size: 1.2rem;
  color: seaweedColor;
  text-decoration: none;
  margin: .8rem;
  display: flex;
  justify-content: center;
}
