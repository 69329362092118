@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor, smallWidth, mobileThreshold from variables;

@import '../../../../../assets/stylesheets/mixins.scss';

@include assetWidthInAddImageControl(10rem);

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
}

.container:nth-of-type(3n - 1) {
  margin: 0 1.8rem 1.4rem;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  margin: 0 0 .8rem;
  border: none;
  background: none;
  padding: 0;
}

.imageContainer:hover {
  opacity: 1;
}

.image {
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: rgba(85, 85, 85, .6);
  color: white;
  width: 100%;
  height: 100%;
  transition: .5s ease;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  background-color: midnightColor;
  margin: .4rem;
}

.name {
  height: 3rem;
  display: flex;
  flex-wrap: wrap;
  font-size: .8rem;
  color: slateColor;
}

.author {
  max-width: 100%;
  color: slateColor;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: mobileThreshold) {
  @include assetWidthInAddImageControl(8rem);

  .container:nth-of-type(3n - 1) {
    margin: 0 1rem 1.4rem;
  }

  .container {
    margin: 0 1rem 1.4rem;
  }
}
