@value variables: "../../../../../assets/stylesheets/variables.scss";
@value modalWidth, midnightColor, slateColor, seaweedColor, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: modalWidth;
  padding: 4rem;
}

.title {
  font-size: 2.4rem;
  color: midnightColor;
  margin-bottom: .8rem;
}

.subtitle {
  font-size: 1.6rem;
  line-height: 1.6;
  color: slateColor;
  margin-bottom: 1.4rem;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.button {
  margin: 0;
  width: 100%;
}

.buttonCancel {
  background: none;
  border: none;
  color: seaweedColor;
  font-size: 1.2rem;
  padding: 0;
  margin-top: .8rem;
}

.buttonCancel:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 100%;
  }

  .modal {
    width: 90%;
  }
}
