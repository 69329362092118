@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  align-items: center;
  position: initial;
  height: 100%;
  border-left: slateColor solid 1px;
}

.border {
  display: flex;
  align-items: center;
  height: 100%;
}

.colorPicker {
  margin-right: 1.5rem;
  position: initial;
}
