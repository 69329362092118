@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor, fogColor, mobileThreshold from variables;

.button {
  color: seaweedColor;
  border: solid 1px fogColor;
  margin: 0 0 1rem;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer {
  margin: 0 0 2rem;
}

.spinner {
  border-top-color: seaweedColor;
  border-left-color: seaweedColor;
}

@media screen and (max-width: mobileThreshold) {
  .button {
    width: initial;
  }
}
