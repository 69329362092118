@value variables: "../../../../../assets/stylesheets/variables.scss";
@value concreteColor, coalColor, mobileThreshold from variables;

@import '../../../../../assets/stylesheets/mixins.scss';

$imageDimension: 10rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.results {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  overflow-y: auto;
  margin: 2.4rem 0 8.2rem;

  @include scrollbars(concreteColor, coalColor);
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, transparent, concreteColor 30%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottomActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.delete {
  width: 21%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.import {
  width: 77%;
  margin-top: 1rem;
}

.replace {
  width: 100%;
  margin-top: 1rem;
}

.loadingCard {
  width: $imageDimension;
  height: $imageDimension;
  margin: 0 0 1.4rem;
}

.loadingCard:nth-of-type(3n - 1) {
  margin: 0 1.8rem 1.4rem;
}

.fullscreenImageContainer {
  margin: 0 !important;
}

.fullscreenImageButton {
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
}

.fullscreenImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: mobileThreshold) {
  $imageDimension: 8rem;

  .loadingCard {
    width: $imageDimension;
    height: $imageDimension;
  }

  .loadingCard:nth-of-type(3n - 1) {
    margin: 0 1rem 1.4rem;
  }

  .loadingCard {
    margin: 0 1rem 1.4rem;
  }
}
