.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
  overflow-y: auto;
}
