@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, ironColor, largeWidth, mediumWidth, smallWidth from variables;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  max-width: 29rem;
}

.listTitle {
  font-size: 1.4rem;
  color: slateColor;
}

.listText {
  font-size: 1.4rem;
  color: slateColor;
  text-align: center;
  margin: 4.8rem auto 0;
  max-width: 19rem;
}

.analytics {
  flex: 1;
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
  border-radius: 6px;
  padding: 3.2rem;
  margin-left: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 23.4rem;
  }
}

.analyticsTitle {
  font-size: 1.8rem;
  text-align: center;
  color: ironColor;
  margin-top: 2.4rem;
}

.analyticsText {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1.6rem;
  color: ironColor;
  max-width: 57rem;
}

.buttonCreate {
  margin-top: 3.2rem;
}

@media screen and (max-width: largeWidth) {
  .list {
    max-width: 20rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .list {
    max-width: 15rem;
  }
}

@media screen and (max-width: smallWidth) {
  .content {
    flex-direction: column;
  }

  .analytics {
    margin-left: 0;
    margin-top: 1.6rem;
  }

  .list {
    max-width: unset;
    flex-grow: unset;
  }

  .listText {
    margin: 1rem 0;
    max-width: unset;
    text-align: left;
  }
}
