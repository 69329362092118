@value variables: "../../../../../../assets/stylesheets/variables.scss";
@value fogColor, colorPickerBorderColor, slateColor from variables;

.container {
  width: 22rem;
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: -.6rem;
  box-sizing: content-box;
  box-shadow: rgba(0, 0, 0, .1) 0 1.2rem 1.6rem;
}

.divider {
  width: 100%;
  height: .7rem;
  border-bottom: 1px solid colorPickerBorderColor;
}

.title {
  font-size: 1.2rem;
  color: slateColor;
  padding: 1rem 0 0 1rem;
}

.content {
  display: flex;
  flex-wrap: wrap;
  padding: .6rem 0 0 1rem;
}

.swatch {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 1rem 1rem 0;
  border-radius: 4px;
  border: 1px solid fogColor;
}
