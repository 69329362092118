@value variables: "../../../../../assets/stylesheets/variables.scss";
@value concreteColor, slateColor, steelColor, shadowSmall, fadeInOpacity from variables;

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  padding: .8rem 0;
  box-shadow: shadowSmall;
  z-index: 2;
  animation: fadeInOpacity .15s linear;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: left;
  color: slateColor;
  padding: 0 1.6rem;
  border: none;
  background: none;

  img {
    width: 3.2rem;
    height: 3.2rem;
    padding: .4rem;
  }
}

.option:disabled {
  color: steelColor;

  img {
    opacity: .5;
  }
}

.option:hover:enabled {
  background: concreteColor;
}
