@value variables: "../../../../../assets/stylesheets/variables.scss";
@value concreteColor, coalColor, midnightColor, fogColor, fadeInOpacity, mobileThreshold from variables;

@import '../../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  animation: fadeInOpacity .2s linear;
}

.modalContent {
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  padding: 3.2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.2rem;
}

.hide {
  background: none;
  border: none;
}

.title {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  color: midnightColor;
  overflow: hidden;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}

.focusedContent {
  composes: modalContent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  flex: 1;
}

.focusedCard {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  margin: 1.4rem 0;
}

.focusedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.scrollable {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  overflow-y: auto;

  @include scrollbars(concreteColor, coalColor);
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: max-content;
}

.cardBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(1, 1, 1, .4) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.buttonExpand {
  display: flex;
  align-items: center;
  margin: 2rem 1.2rem 1.2rem auto;

  img {
    width: 1.8rem;
  }
}

.cardBottomFocused {
  display: flex;
  justify-content: flex-end;
}

.deselectFocused {
  margin-right: 2.4rem;
  padding: 0;
  background: none;
  border: none;
  color: midnightColor;
  transition: none;
}

.deselectFocused:hover:enabled {
  background: none;
  border: none;
  color: midnightColor;
}

.bottomOptions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2.4rem;

  button {
    width: max-content;
    margin-top: 0;
  }
}

.deselect {
  margin-right: 2rem;
  padding: 0;
  border: none;
  background: none;
}

.deselect:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: mobileThreshold) {
  .title {
    font-size: 1.8rem;
  }

  .deselect {
    font-size: 1.4rem;
  }

  .cardBottom {
    background: fogColor;
    position: initial;
  }

  .buttonExpand {
    margin: .4rem .4rem .4rem auto;

    img {
      width: 2.2rem;
    }
  }
}
