@value variables: "../../../../assets/stylesheets/variables.scss";
@value mobileThreshold, slateColor from variables;

.section {
  display: flex;
  width: 100%;
  margin: 1.2rem 0;
}

.header {
  width: 15rem;
  margin: 0;
  font-size: 1.4rem;
  letter-spacing: .5px;
  color: black;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 3.4rem;
}

.info {
  letter-spacing: .75px;
  font-size: 1.4rem;
  color: slateColor;
}

@media screen and (max-width: mobileThreshold) {
  .section {
    display: inline;
  }

  .content {
    margin: 1rem 0 0;
  }
}
