@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, steelColor, seaweedColor from variables;

.radio {
  font-size: 1.4rem;
  color: slateColor;
  display: flex;
  align-items: center;
  margin: 0;
}

.radioInput {
  display: flex;
  margin: 0 .5rem 0 0;
  border-radius: 50%;
  border: 1px solid steelColor;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .radioControl {
      border: 3px solid white;
      background-color: seaweedColor;
    }
  }
}

.radioControl {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: white;
}

.radioControl:hover {
  cursor: pointer;
}

.radioInputDefault {
  margin: 0;
}
