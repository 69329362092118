@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, midnightColor, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: concreteColor;
  padding: 4.2rem 4.6rem;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

@media screen and (max-width: mobileThreshold) {
  .content {
    padding: 4.2rem 2rem;
  }
}
