@value variables: "../../../../assets/stylesheets/variables.scss";
@value royalColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 1rem;
  background: none;
  border: none;
  transition: background-color 120ms linear;
}

.container:disabled {
  opacity: .7;
}

.text {
  color: white;
  font-size: 1rem;
}

.icon {
  width: 4.6rem;
  margin: .6rem 0 0;
}

.open {
  background: royalColor;
}

.tooltip {
  top: 88%;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    justify-content: center;
    height: 100%;
    padding: 0;
  }

  .icon {
    margin: 0;
  }
}
