@value variables: "../../../../assets/stylesheets/variables.scss";
@value titleColorLight, crimsonColor, concreteColor, midnightColor, smallWidth from variables;

.container {
  flex: 1;
  display: flex;
  position: relative;
}

.projectName {
  font-size: 1.6rem;
  flex: 1;
  background: none;
  border: none;
  color: titleColorLight;
  padding: .8rem .5rem;
  margin-left: -.5rem;
  margin-top: -.2rem;
  border-radius: 4px;
  transition: all .1s ease;
}

.projectName:focus:not(.error) {
  color: midnightColor;
  background-color: concreteColor;
  transition: all .1s ease;
}

.error {
  background-color: concreteColor;
  border: solid 1px crimsonColor;
  color: crimsonColor;
}

.errorLabel {
  position: absolute;
  top: 20%;
  left: 92%;
  color: white;
  background: crimsonColor;
  padding: .2rem .5rem;
  border-radius: 3px;
  font-size: 1.2rem;
  z-index: 3;
}

@media screen and (max-width: smallWidth) {
  .projectName {
    max-width: 12rem;
  }
}
