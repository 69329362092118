@value variables: "../../../../../assets/stylesheets/variables.scss";
@value shadowSmall, mobileThreshold from variables;

.options {
  width: 18rem;
  border: none;
  box-shadow: shadowSmall;
  left: 0;
  top: calc(100% + .8rem);

  // z-index has to be higher than controls options
  z-index: 103;
}

.option {
  text-align: left;
}

.saveText {
  color: white;
  font-size: 1.6rem;
}

@media screen and (max-width: mobileThreshold) {
  .options {
    left: unset;
  }
}
