@value variables: "../../../../../assets/stylesheets/variables.scss";
@value templateCardSmall, templateCardSmallInMobile, mobileThreshold from variables;

.card {
  height: templateCardSmall;
  width: templateCardSmall;
}

.title {
  font-size: 1.8rem;
}

@media screen and (max-width: mobileThreshold) {
  .card {
    height: templateCardSmallInMobile;
    width: templateCardSmallInMobile;
  }
}
