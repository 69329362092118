@value variables: "../../../../assets/stylesheets/variables.scss";
@value mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  padding: 1.4rem 2.4rem;
}

.colorText {
  font-size: 1.2rem;
  margin-bottom: .8rem;
  width: 100%;
}

.button {
  background: none;
  border: none;
  width: 1.9rem;
  height: 1.9rem;
}

.color {
  font-size: 1.4rem;
  margin-left: .8rem;
}

.customPosition {
  position: relative;
}

.paintBucket {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 1.5rem;
  background: none;
  border: none;
}

.paintBucketLine {
  background: white;
  height: .4rem;
  width: 100%;

  div {
    height: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 0;
    margin: -.8rem 0 0 2.4rem;
  }

  .colorContainer {
    background: none;
  }
}
