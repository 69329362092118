@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, midnightColor, extraLargeWidth, largeWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  margin: 0 0 2.4rem 2.4rem;
  width: 22rem;
}

.label {
  font-size: 1.4rem;
  color: slateColor;
}

.spinnerContainer {
  height: 100%;
  width: 45%;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.referrer {
  font-size: 1.4rem;
  color: slateColor;
  font-weight: 300;
}

@media screen and (max-width: extraLargeWidth) {
  .container {
    width: 19rem;
  }
}

@media screen and (max-width: largeWidth) {
  .container {
    width: 16.5rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 100%;
    margin: 0 0 2.4rem;
  }
}
