@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, mediumWidth, largeWidth from variables;

.step3container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.headerInfo {
  flex-basis: 100%;
  height: 2.9rem;
  font-size: 2.4rem;
  color: slateColor;
  margin-bottom: 2.4rem;
}

.inputWantContainer {
  min-width: 50%;
  max-width: 29.3rem;
  margin-bottom: 2.4rem;
}

.otherPlaceHolder {
  height: 11rem;
}

.inputOtherContainer {
  margin-bottom: 2.4rem;
}

.inputOtherContainer textarea {
  width: 50%;
  height: 11rem;
}

@media screen and (max-width: mediumWidth) {
  .row {
    display: unset;
  }

  .inputWantContainer {
    width: 100%;
    margin-left: unset;
  }

  .inputOtherContainer textarea {
    width: 100%;
  }
}
