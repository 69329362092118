@value variables: "../../../../assets/stylesheets/variables.scss";
@value mobileThreshold from variables;

.container {
  margin-left: 5rem;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    margin-left: 0;
    margin-top: 5rem;
  }
}
