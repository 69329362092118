@value variables: "../../../../../../assets/stylesheets/variables.scss";
@value templateCardSmall, midnightColor, templateCardSmallInMobile, mobileThreshold from variables;

.blankcard {
  height: templateCardSmall;
  width: templateCardSmall;
}

.suggestions {
  display: flex;
  justify-content: space-between;
  color: midnightColor;
  width: 100%;
  padding-bottom: 3.6rem;
}

.spinnerContainer {
  min-height: templateCardSmall;
  margin-bottom: 3.6rem;
}

.spinner {
  margin-top: -3rem;
  margin-left: -3rem;
}

.spinnerIcon {
  width: 4rem;
  height: 4rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.templateTitle {
  font-size: 1.6rem;
}

.seeAllButton {
  font-size: 1.2rem;
  padding: 0;
}

@media screen and (max-width: mobileThreshold) {
  .blankcard {
    height: templateCardSmallInMobile;
    width: templateCardSmallInMobile;
  }

  .spinnerContainer {
    min-height: templateCardSmallInMobile;
  }
}
