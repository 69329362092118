@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, seaweedColor, seaweedFadedColor, concreteColor, mediumWidth from variables;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  padding: 0 2.4rem;
  background-color: midnightColor;
}

.content {
  display: flex;
  width: max-content;
  height: 100%;
}

.linksContainer {
  display: flex;
  width: 100%;
  padding-bottom: 4rem;
  border-bottom: solid 4px concreteColor;
  border-radius: 2px;
}

.linksContainer:last-of-type {
  border-bottom: none;
}

.signButton {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  text-decoration: none;
  border-radius: 4px;
  padding: .8rem 1.6rem;
}

.signin {
  composes: signButton;
  margin-right: .6rem;
  background-color: seaweedFadedColor;
  color: seaweedColor;
  border: solid 1px seaweedColor;
}

.signup {
  composes: signButton;
  margin-left: .6rem;
  background: none;
  color: white;
  border: solid 1px seaweedColor;
}

.listTitle {
  color: seaweedColor;
  font-weight: bold;
  font-size: 1.6rem;
  text-decoration: none;
  padding: .8rem 0;
  margin-top: 3.2rem;
  width: 30%;
}

.listContent {
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  flex: 1;
}

.listItem {
  padding: .8rem 0;
  color: midnightColor;
  font-size: 2.2rem;
  text-decoration: none;
}

.collapsibleContainer {
  margin-right: 2.4rem;
}

.collapsibleTitle {
  color: white;
  font-size: 1.6rem;
}

.collapsibleOptions {
  width: max-content;
  border-radius: 12px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
}

@media screen and (max-width: mediumWidth) {
  .content {
    padding: 2.4rem 2.4rem 12.8rem;
    width: 100%;
    overflow: auto;
  }

  .signButton {
    width: 100%;
    height: 5.6rem;
  }

  .signin {
    background-color: seaweedFadedColor;
    color: seaweedColor;
    border: none;
  }

  .signup {
    background-color: seaweedColor;
    color: midnightColor;
    border: none;
  }
}
