@value variables: "../../../assets/stylesheets/variables.scss";
@value mediumWidth, smallWidth from variables;

.canvas {
  flex-grow: 1;
  margin: 2rem 0 0;

  :global(.simplebar-placeholder) {
    height: unset !important;
  }

  :global(.simplebar-content-wrapper) {
    outline: none;
  }
}

.largeContainer {
  overflow: hidden;
}

.eyeDropper {
  cursor: crosshair;
}

.placeholder {
  display: none;
}

.productTour {
  position: absolute;
  top: 30%;
  left: 10%;
  z-index: 4;
}

.templateTip {
  position: absolute;
  bottom: 3rem;
  left: calc(50% - 20rem);

  // z-index has to be higher than controls options
  z-index: 104;
}

@media screen and (max-width: mediumWidth) {
  .productTour {
    left: 5%;
  }

  .templateTip {
    left: calc(50% - 15rem);
  }
}

@media screen and (max-width: smallWidth) {
  .productTour {
    top: 20%;
    left: calc(50% - 2rem - 3.5rem);
  }
}
