@value variables: "../../../../assets/stylesheets/variables.scss";
@value concreteColor, midnightFadedColor, midnightColor, fadeInOpacity, fadeOutOpacity, mediumWidth, smallWidth from variables;

@import '../../../../assets/stylesheets/mixins.scss';

.page {
  display: flex;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  background-color: midnightFadedColor;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  // z-index has to be higher than controls options
  z-index: 110;
}

.header {
  display: flex;
  align-items: center;
}

.close {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  background: none;
  border: none;
  padding: 0;

  img {
    height: 4rem;
    width: 4rem;
  }
}

.content {
  display: flex;
  justify-content: center;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 5.4rem;
  max-width: 40rem;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
}

.headerDetail {
  font-size: 1.8rem;
  font-weight: 300;
  color: white;
  margin-top: 2.4rem;
  margin-bottom: .8rem;
}

.detailsInfo {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 3.2rem;
  font-weight: 300;
  color: white;
}

.details {
  font-size: 1.4rem;
  font-weight: 300;
  color: white;
}

.mount {
  animation: fadeInOpacity .25s ease-in;
}

.unmount {
  animation: fadeOutOpacity .25s ease-out;
  animation-fill-mode: forwards;
}

@media screen and (max-width: mediumWidth) {
  .info {
    margin-left: 1.4rem;
  }
}

@media screen and (max-width: smallWidth) {
  .page {
    background-color: midnightColor;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3rem;
    overflow-y: auto;

    @include scrollbars(midnightFadedColor, concreteColor);
  }

  .close {
    position: relative;
    right: unset;
    top: unset;
  }

  .content {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .slider {
    margin-top: 2.4rem;
  }

  .info {
    flex-direction: column-reverse;
    padding: 0 2rem;
    margin: 0;
    align-self: unset;
    max-width: 100%;
  }

  .detailsContainer {
    flex-direction: row;
  }

  .detailsInfo {
    flex: 1;
  }

  .detailsInfo:first-child {
    margin-right: 2rem;
  }

  .title {
    font-size: 1.6rem;
  }
}
