@value variables: "../../../../assets/stylesheets/variables.scss";
@value coalColor, concreteColor, slateColor, toolbarControlsWidth, shadowSmall, mobileThreshold from variables;

@import '../../../../assets/stylesheets/mixins.scss';

.container {
  background-color: coalColor;
  width: toolbarControlsWidth;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: shadowSmall;
  padding: 2.4rem 0;
  border: 1px solid slateColor;
  border-left: 0;
  overflow-y: auto;

  @include scrollbars(coalColor, concreteColor);
}

.closeContainer {
  position: relative;
}

.closeButton {
  background: none;
  border: none;
  position: absolute;
  top: -1.6rem;
  right: .8rem;
  padding: 0;
}

.title {
  font-size: 1.6rem;
  color: white;
  margin-bottom: .8rem;
  padding: 0 2.4rem;
}

.layoutButton {
  height: 3.2rem;
  border: none;
  background: none;
  padding: 0;
  margin: 0 0 0 2.4rem;
  display: flex;
  align-items: center;

  img {
    width: 2.4rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 100vw;
    flex-direction: row;
    padding: .8rem 0;
  }
}
