@value variables: "../../../../../assets/stylesheets/variables.scss";
@value fogColor, slateColor, steelColor, mobileThreshold from variables;

.option {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
}

.title {
  width: max-content;
  font-size: 1.4rem;
  color: fogColor;
  margin-bottom: 1.4rem;
}

.content {
  display: flex;
}

.label {
  width: 39%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: fogColor;
}

.label:first-child {
  margin-right: 1rem;
}

.input {
  height: 4.8rem;
  border-radius: 4px;
  color: slateColor;
  font-size: 1.2rem;
  line-height: 1.2;
  margin-top: .4rem;
  padding: .8rem 2.2rem .8rem .8rem;
}

.pixels {
  font-size: 1.2rem;
  color: steelColor;
  margin-left: .8rem;
  margin-top: 1.5rem;
  align-self: center;
}

@media screen and (max-width: mobileThreshold) {
  .option {
    margin-bottom: 0;
    margin-top: 3.2rem;
  }

  .content {
    flex-direction: column;
    width: 8rem;
  }

  .label {
    width: 100%;
  }

  .label:first-child {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
