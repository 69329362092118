@value variables: "../../../assets/stylesheets/variables.scss";
@value crimsonColor from variables;

.button {
  width: 100%;
  float: right;
}

.error {
  color: crimsonColor;
}
