@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, shadowSmall, largeWidth, mediumWidth, smallWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  max-width: 29rem;
}

.listTitle {
  font-size: 1.4rem;
  color: slateColor;
}

.listProjects {
  margin: 2.4rem 0;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
}

.selectContainer {
  margin-top: 1rem;
}

.analytics {
  flex: 1;
  background: white;
  box-shadow: shadowSmall;
  border-radius: 6px;
  padding: 3.2rem;
  margin-left: 3.2rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-wrap: wrap;
}

.projectNameContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2.4rem;
  min-width: 0;
}

.projectNameLabel {
  font-size: 1.4rem;
  color: slateColor;
}

.projectName {
  font-size: 2.4rem;
  color: slateColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: largeWidth) {
  .list {
    max-width: 20rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .list {
    max-width: 15rem;
  }
}

@media screen and (max-width: smallWidth) {
  .content {
    flex-direction: column;
  }

  .analytics {
    margin-left: 0;
    margin-top: 1.6rem;
  }

  .list {
    max-width: unset;
    flex-grow: unset;
  }
}

@media screen and (max-width: mobileThreshold) {
  .header {
    flex-direction: column;
  }

  .projectName {
    font-size: 1.6rem;
  }
}
