@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, coalColor, shadowSmall, toolbarControlsWidth, concreteColor, mobileThreshold from variables;

@import '../../../../assets/stylesheets/mixins.scss';

.container {
  background-color: coalColor;
  width: toolbarControlsWidth;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: shadowSmall;
  border: 1px solid slateColor;
  border-left: 0;
  position: relative;
  padding: 2.4rem 2.4rem;
  overflow-y: auto;

  @include scrollbars(coalColor, concreteColor);
}

.closeButton {
  background: none;
  border: none;
  position: absolute;
  right: .8rem;
  top: .8rem;
  padding: 0;
}

.title {
  font-size: 1.6rem;
  color: white;
  margin-bottom: 3.2rem;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    width: 100vw;
  }
}
