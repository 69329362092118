@value variables: "../../../assets/stylesheets/variables.scss";
@value seaweedColor, emeraldColor, crimsonColor, smallWidth from variables;

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  float: right;
}

.link {
  color: seaweedColor;
  font-size: 1.2rem;
  text-decoration: none;
  margin: 2rem 1rem 0 0;
}

.link:hover {
  color: emeraldColor;
}

.error {
  color: crimsonColor;
}

@media screen and (max-width: smallWidth) {
  .buttons {
    flex-direction: column-reverse;
  }

  .link {
    margin: 2rem 0 0;
  }
}
