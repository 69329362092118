@value variables: "../../../assets/stylesheets/variables.scss";
@value extraLargeWidth, smallWidth from variables;

.card {
  height: 20vw;
  width: 20vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}

.smallCard {
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  height: 100%;
}

.image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  right: .8rem;
  bottom: .6rem;
  z-index: -1000;
}

.overlayButtons {
  position: absolute;
  right: 1.4rem;
  bottom: 1.1rem;
  left: 1.4rem;
  z-index: -1000;
}

.card:hover .overlay {
  z-index: 10;
}

.card:hover .overlayButtons {
  z-index: 10;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.buttonPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem 0 0;
  font-weight: 300;
  width: 100%;
  padding: 0;
}

.buttonSelect {
  margin: 0;
  width: 100%;
  padding: 0;
}

@media screen and (max-width: extraLargeWidth) {
  .card {
    height: 17vw;
    width: 17vw;
  }
}

@media screen and (max-width: smallWidth) {
  .card {
    height: 40vw;
    width: 40vw;
  }
}
