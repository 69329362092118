@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, mediumWidth, largeWidth from variables;

.step2container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.headerInfo {
  flex-basis: 100%;
  height: 2.9rem;
  font-size: 2.4rem;
  color: slateColor;
  margin-bottom: 2.4rem;
}

.row {
  width: 100%;
  display: flex;
  margin-bottom: 2.4rem;
}

.inputCategoryContainer {
  min-width: 50%;
  padding-right: 1.6rem;
  max-width: 29.3rem;
}

.inputIndustryContainer {
  min-width: 50%;
  padding-left: 1.6rem;
  max-width: 29.3rem;
}

.inputAgeContainer,
.inputGenderContainer {
  min-width: 33%;
  max-width: 18.4rem;
  padding-right: 1.6rem;
}

.inputRaceContainer {
  min-width: 33%;
  max-width: 18.4rem;
}

.inputIncomeContainer,
.inputGeographicContainer {
  margin-bottom: 2.4rem;
  min-width: 50%;
  max-width: 29.3rem;
}

@media screen and (max-width: mediumWidth) {
  .row {
    display: unset;
    width: 100%;
  }

  .inputCategoryContainer,
  .inputIndustryContainer,
  .inputRaceContainer,
  .inputAgeContainer,
  .inputGenderContainer {
    width: 100%;
    max-width: unset;
    padding-right: unset;
    padding-left: unset;
    margin-bottom: 2.4rem;
  }

  .inputGeographicContainer,
  .inputIncomeContainer {
    max-width: unset;
  }
}
