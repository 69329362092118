@value variables: "../../../../assets/stylesheets/variables.scss";
@value fadeInOpacity, coalColor, ironColor from variables;

.container {
  display: flex;
  align-items: center;
  position: relative;
  background-color: ironColor;
  padding: .7rem 1rem;
  border-radius: 4px;
}

.control {
  background: none;
  border: none;
  padding: 0;
}

.button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: white;
  text-align: center;
  padding: 0 .8rem;
  height: 2.7rem;
  width: 4.8rem;
  border-radius: 4px;

  &.active {
    background: rgba(255, 255, 255, .1);
  }
}

.button:hover {
  background-color: rgba(255, 255, 255, .1);
}

.options {
  position: absolute;
  bottom: calc(100% + .2rem);
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 7.6rem;
  background-color: coalColor;
  z-index: 11;
  padding: .8rem 0;
  border-radius: 4px;
  animation: fadeInOpacity .3s linear;
}

.option {
  width: 100%;
  height: 3.2rem;
  font-size: 1.2rem;
  color: white;
  text-align: center;
  background-color: transparent;
  border: none;
}

.option:hover {
  background-color: rgba(255, 255, 255, .1);
}
