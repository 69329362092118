@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, fadeInOpacity from variables;

.tooltip {
  background-color: slateColor;
  padding: .8rem 1rem;
  position: absolute;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  top: 115%;
  z-index: 2;
  display: table;
  border-radius: 4px;
  white-space: nowrap;
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: .43px;
  animation: fadeInOpacity .3s ease-in-out;
}

.followMouse {
  right: unset;
  position: fixed;
  transform: inherit;
}
