@value variables: "../../../../assets/stylesheets/variables.scss";
@value fogColor, seaweedColor, coalColor, mobileThreshold from variables;

.content {
  max-width: 39rem;
}

.header {
  width: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 2.4rem 3.1rem 2.5rem;
  background-image: url(../../../../assets/images/blue-header-with-skyblue-circles.svg);
  background-size: cover;
}

.title {
  display: flex;
  font-size: 1.8rem;
  padding-bottom: 1.2rem;
  color: white;
}

.subtitle {
  font-size: 1.4rem;
  color: white;
}

.body {
  width: 100%;
  background-color: white;
  margin-top: -.3rem;
  border-bottom: 1px solid fogColor;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 6.3rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}

.close {
  top: 1.4rem !important;
  right: 1.1rem !important;
}

.button {
  background: none;
  border: none;
  padding: 0;
}

.listContainer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.listToolbar {
  display: flex;
  align-items: center;
  padding-left: 3.1rem;
  height: 4rem;

  img {
    width: 4rem;
  }

  .listItem {
    padding-left: 1.4rem;
    font-size: 1.4rem;
    color: seaweedColor;
  }
}

.collageIcon {
  padding: .7rem;
}

.singleImageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem 0;
  background-color: coalColor;
}

.singleImage {
  width: 100%;
}

.collageContainer {
  display: flex;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.exportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem;

  img:first-child {
    padding-bottom: 2.4rem;
  }

  img {
    width: 100%;
  }
}

.buttonStart {
  margin: 0 0 0 1.2rem;
}

.buttonStory {
  background: none;
  border: none;
  font-size: 1.4rem;
  color: seaweedColor;
  padding: 0;
  margin: 0;
}

.buttonStory:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 90%;
  }

  .header {
    padding: 1.8rem 3.1rem 1.9rem;
  }

  .singleImage {
    width: 80%;
  }
}
