@value variables: "../../../../../assets/stylesheets/variables.scss";
@value concreteColor, slateColor, coalColor, steelColor, shadowSmall, fadeInOpacity from variables;

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: coalColor;
  border-radius: 4px;
  overflow: hidden;
  padding: .8rem 0;
  box-shadow: shadowSmall;
  z-index: 102;
  animation: fadeInOpacity .15s linear;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: .4rem;
  margin: 0;

  img {
    width: 3.2rem;
    height: 3.2rem;
  }
}
