@value variables: "../../assets/stylesheets/variables.scss";
@value borderMediumColor, seaweedColor, emeraldColor, ironColor from variables;

// stylelint-disable

:global(.react-toggle-track) {
  background-color: borderMediumColor;
  width: 4.2rem;
}

:global(.react-toggle--checked .react-toggle-track) {
  background-color: seaweedColor;
  width: 4.2rem;
}

:global(.react-toggle--checked .react-toggle-thumb) {
  left: 19px;
}

:global(.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track) {
  background-color: emeraldColor;
}

// stylelint-enable
