@value variables: "../../../../assets/stylesheets/variables.scss";
@value titleColorLight, slateColor, concreteColor, crimsonColor, mediumWidth, mobileThreshold from variables;

.title {
  font-size: 3.2rem;
  line-height: 1.2;
  text-align: center;
  color: titleColorLight;
  margin-bottom: 4.2rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 83.3rem;
  background: white;
  padding: 3rem 4rem;
}

.header {
  font-size: 2.4rem;
  line-height: 1.2;
  color: slateColor;
  margin-bottom: 1rem;
  text-align: center;
}

.subheader {
  composes: header;
  font-size: 1.8rem;
}

.note {
  display: flex;
  background: concreteColor;
  border-radius: 8px;
  padding: 1.3rem;
  font-size: 1.8rem;
  line-height: 1.3;
  color: slateColor;
  margin: 2.6rem 0 1.5rem;
}

.noteTitle {
  min-width: max-content;
  margin-right: .8rem;
}

.noteText {
  text-align: center;
}

.accountContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
}

.accountTitle {
  font-size: 2.4rem;
  line-height: 1.2;
  color: slateColor;
  margin-bottom: 2.2rem;
}

.brandName {
  font-size: 1.6rem;
  line-height: 1.2;
  color: slateColor;
}

.card {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.6rem;
}

.keepProjects {
  font-size: 1.2rem;
  color: slateColor;
  min-width: max-content;
  margin-left: 1rem;
}

.deleteProjects {
  font-size: 1.2rem;
  color: crimsonColor;
  min-width: max-content;
  margin-left: 1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: 0;
  }
}

.downgradeButton {
  margin-left: 1rem;
}

@media screen and (max-width: mediumWidth) {
  .content {
    width: 90%;
  }

  .note {
    flex-direction: column;
    font-size: 1.4rem;
    margin: 1rem 0 3rem;
  }

  .noteText {
    text-align: left;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 95%;
  }
}
