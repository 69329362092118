@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, midnightColor, largeWidth, mediumWidth from variables;

.content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  position: relative;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  max-width: 29rem;
}

.listTitle {
  font-size: 1.4rem;
  color: slateColor;
}

.listPosts {
  margin: 2.4rem 0;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
}

.noPosts {
  font-size: 1.8rem;
  color: slateColor;
  margin-top: 7rem;
  text-align: center;
}

.analytics {
  flex: 1;
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
  border-radius: 6px;
  padding: 3.2rem;
  margin-left: 3.2rem;
  display: flex;
  flex-direction: column;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.loginTitle {
  font-size: 1.8rem;
  text-align: center;
  color: slateColor;
  margin: 2rem 0 1.6rem;
}

.loginSubtitle {
  width: 57rem;
  font-size: 1.4rem;
  line-height: 1.57;
  text-align: center;
  color: slateColor;
}

.spinnerContainer {
  width: 50%;
  padding: 3rem;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

@media screen and (max-width: largeWidth) {
  .list {
    max-width: 20rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .list {
    max-width: 15rem;
  }
}
