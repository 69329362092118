@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, smallWidth from variables;

.templatesHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.categoryHeader {
  font-size: 1.8rem;
  align-self: center;
  color: midnightColor;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pagination {
  display: flex;
  align-items: flex-end;
  align-self: center;
}

.seeAll {
  font-size: 1.4rem;
  letter-spacing: .066rem;
  color: midnightColor;
  text-decoration: blink;
  background: none;
  border: none;
  align-self: center;
  min-width: max-content;
}

.button {
  background: none;
  border: none;
  align-self: center;
  padding: 0;
}

.imageIcon {
  width: 3.2rem;
}

@media screen and (max-width: smallWidth) {
  .categoryHeader {
    font-size: 1.2rem;
  }

  .seeAll {
    font-size: 1.2rem;
  }

  .imageIcon {
    width: 2.4rem;
  }
}
