@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: slateColor;
  padding-top: 15rem;
}

.title {
  font-size: 1.8rem;
  margin: 1.6rem 0 .8rem;
  text-align: center;
}

.text {
  font-size: 1.4rem;
  text-align: center;
}
