@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  border-top: 1px solid slateColor;
  padding: 1.4rem 2.4rem;
}

.countText {
  font-size: 1.2rem;
  margin-bottom: .8rem;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  height: 3.2rem;
  background: white;
  border: 1px solid fogColor;
  border-radius: 4px;
}

.button {
  background: none;
  border: none;
  color: slateColor;
  font-size: 3rem;
  font-weight: 300;
  padding: 0 1.6rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.button:last-child {
  margin-top: -.5rem;
}

.size {
  font-size: 1.4rem;
  color: slateColor;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    border-top: none;
    padding: 0 0 0 2.4rem;
  }

  .countText {
    width: unset;
    margin-right: 1.2rem;
    margin-bottom: 0;
    font-size: 1.2rem;
  }
}
