@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor from variables;

.container {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.font {
  font-size: 1.4rem;
  color: slateColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete {
  margin: 0 0 0 1.3rem;
  border: none;
  background: none;
  color: seaweedColor;
}

.disabled {
  cursor: default;
}
