@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, fadeInOpacity from variables;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  animation: fadeInOpacity .2s linear;
}

.background {
  max-height: 100%;
  width: 100%;
  background-color: rgba(26, 26, 26, .8);
}

.backgroundLight {
  background-color: rgba(26, 26, 26, .3);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: concreteColor;
  border-radius: 8px;
}

.contentAnimation {
  transition: all 1s ease-in-out;
  animation: fadeInOpacity .3s linear;
}

.hide {
  background: none;
  border: none;
  position: absolute;
  right: 2.4rem;
  top: 2.4rem;
}

.curvedContainer > div {
  border-radius: 8px;
}
