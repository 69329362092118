@value variables: "../../../assets/stylesheets/variables.scss";
@value crimsonColor, smallWidth from variables;

.button {
  float: right;
}

.error {
  color: crimsonColor;
}

@media screen and (max-width: smallWidth) {
  .button {
    width: 100%;
  }
}
