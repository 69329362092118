@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, midnightColor from variables;

.option {
  font-size: 1.6rem;
  color: slateColor;
  background: none;
  border: none;
  margin: 1.6rem 0;
  padding: 0 0 0 3rem;
  width: 100%;
  text-align: left;
}

.linkOption {
  font-size: 1.6rem;
  color: slateColor;
  margin: 1.6rem 0;
  padding: 0 0 0 3rem;
  text-decoration: none;
  width: 100%;
}

.lastOption {
  border-top: solid 1px fogColor;
  margin: auto 0 0;
  padding: 3rem;
}

.collapsibleContainer {
  height: 3.2rem;
  width: calc(100% - 6rem);
  background: midnightColor;
  align-self: flex-start;
  margin: 1.6rem 3rem;
  border-radius: 4px;
}

.collapsibleButton {
  justify-content: space-between;
  width: 100%;
  color: white;
  padding: 0 .8rem 0 1.6rem;
  font-size: 1.4rem;
}

.collapsibleOptions {
  background: midnightColor;
  width: 100%;
  top: calc(100% - 4px);
  border-radius: 0 0 4px 4px;
}

.collapsibleOption {
  color: white;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}
