@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowSmall, midnightColor, slateColor, largeWidth, mediumWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 83.6rem;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.header {
  font-size: 1.8rem;
  color: midnightColor;
  margin-bottom: 1rem;
}

.editorSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 2.2rem;
}

.noEditorTitle {
  font-size: 1.8rem;
  text-align: center;
  color: slateColor;
  margin: 2rem 0 1.6rem;
}

.noEditorContent {
  max-width: 57rem;
  font-size: 1.4rem;
  line-height: 1.57;
  text-align: center;
  color: slateColor;
}

@media screen and (max-width: largeWidth) {
  .container {
    width: 63.3rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }
}
