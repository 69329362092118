@value variables: "../../../assets/stylesheets/variables.scss";
@value largeWidth, mobileThreshold from variables;

.projects {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4.2rem;
  margin-top: 2.4rem;
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.blankcard {
  width: 20vw;
  margin: 0 3.2rem 3.2rem 0;
}

.blankcard:last-child {
  margin-right: 0;
}

@media screen and (max-width: largeWidth) {
  .blankcard {
    width: 27vw;
  }
}

@media screen and (max-width: mobileThreshold) {
  .projects {
    padding-bottom: 2rem;
    margin-top: 4.2rem;
  }
}
