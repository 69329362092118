@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor, concreteColor, seaweedColor, slateColor, largeWidth, mobileThreshold from variables;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: concreteColor;
  padding-top: 3rem;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: 47rem;
  display: flex;
  flex-direction: column;
  padding: 3.8rem 6.4rem;
  background-color: white;
}

.logo {
  margin: 0 auto 1.6rem;
  width: 4.8rem;
}

.signupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  span {
    color: slateColor;
    font-size: 1.4rem;
  }
}

.link {
  color: seaweedColor;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: .5rem;
}

.title {
  font-size: 1.8rem;
  color: midnightColor;
  text-align: center;
  margin: .1rem 0 2.4rem;
}

@media screen and (max-width: largeWidth) {
  .container {
    justify-content: center;
  }
}

@media screen and (max-width: mobileThreshold) {
  .form {
    width: 97%;
    padding: 1.9rem 3.15rem;
    margin: 0 1rem;
  }
}
