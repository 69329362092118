@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, midnightColor, smallWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.subnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8.4rem;
  background-color: white;
  padding: 0 4.2rem;
}

.title {
  font-size: 1.8rem;
  color: black;
  text-align: left;
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  flex: 1;
  padding: 2.4rem 4.6rem;
}

.searchContainer {
  padding: 0 1.5rem;
}

.searchInput {
  height: 4.8rem;
}

.searchGrid {
  background-color: transparent;
  padding: 0;
  animation: none;
}

.searchText {
  color: midnightColor;
}

.spinnerIcon {
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

@media screen and (max-width: smallWidth) {
  .content {
    padding: 2.4rem 2rem 2rem;
  }

  .subnav {
    padding: 0 2rem;
  }

  .searchContainer {
    min-width: 25rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .searchContainer {
    min-width: 20rem;
  }
}
