@value variables: "../../assets/stylesheets/variables.scss";
@value steelColor, fogColor, shadowSmall, fadeInOpacity from variables;

.container {
  display: flex;
  position: relative;
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
}

.button:disabled {
  cursor: default;
  opacity: .5;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.animationIcon {
  transition-duration: 200ms;
}

.closedIcon {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
  text-align: left;
  padding: .8rem 0;
  overflow: hidden;
  z-index: 11;
  animation: fadeInOpacity .3s linear;
}

.group {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: solid 1px fogColor;
  border-bottom: solid 1px fogColor;
}

.groupTitle {
  font-size: 1.2rem;
  line-height: 1.2;
  color: steelColor;
  padding: 1rem 1rem 0;
}
