@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  padding: .8rem 1.4rem;
  border-radius: 4px;
  background-color: slateColor;
  max-width: 75%;
  margin-bottom: 2rem;
  align-self: flex-start;
}

.text {
  color: white;
  font-size: 1.4rem;
  line-height: 1.8;
}
