@value variables: "../../../../../../assets/stylesheets/variables.scss";
@value seaweedColor, emeraldColor, colorPickerBorderColor from variables;

.container {
  width: 22rem;
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: -.6rem;
  box-sizing: content-box;
  box-shadow: rgba(0, 0, 0, .1) 0 1.2rem 1.6rem;
}

.divider {
  width: 100%;
  height: .7rem;
  border-bottom: 1px solid colorPickerBorderColor;
}

.content {
  display: flex;
}

.button {
  border: none;
  background: none;
  padding: 0;
  margin: .8rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 4px;

  &.active {
    background-color: seaweedColor;
  }
}

.cancelButton {
  border: none;
  background: none;
  padding: 0;
  color: emeraldColor;
}
