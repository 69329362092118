@value variables: "../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.button {
  border: none;
  background: none;
  outline: none !important;
  padding: 0;
}

.button:disabled {
  cursor: default;
}

.arrow {
  width: 6.4rem;
}

@media screen and (max-width: smallWidth) {
  .arrow {
    width: 4.2rem;
  }
}
