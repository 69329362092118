@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  align-self: flex-end;
  margin-top: 1.4rem;
}

.saveButton {
  margin-left: .4rem;
  margin-top: 0;
}

.cancelButton {
  margin-right: .4rem;
  margin-top: 0;
}
