/* FONTS */
@value titleFont: 'Open Sans Condensed', sans-serif;
@value baseFont: sans-serif;

/* COLORS */
@value midnightColor: #201C1C;
@value ironColor: #282b2d;
@value coalColor: #313437;
@value slateColor: #555555;
@value steelColor: #9a9a9a;
@value fogColor: #CCCCCC;
@value concreteColor: #f7f7f7;
@value seaweedColor: #2daf92;
@value emeraldColor: #02A07D;
@value forestColor: #036029;
@value mintColor: #59f0cc;
@value melonColor: #d4f9e7;
@value royalColor: #276DF1;
@value iceColor: #47c0ff;
@value crimsonColor: #ce3636;
@value crismonFadedColor: rgba(206, 54, 54, .1);
@value seaweedFadedColor: rgba(45, 175, 146, .2);
@value midnightFadedColor: rgb(32, 28, 28, .9);

@value shadowSmall: 0 2px 6px rgba(0, 0, 0, .1);
@value shadowRegular: 0px 8px 24px rgba(0, 0, 0, 0.25);
@value shadowHover: 0px 2px 20px rgba(0, 0, 0, .2);

@value titleColorLight: #777777;
@value borderMediumColor: #cfcfcf;
@value colorPickerBorderColor: #eeeeee;

/* THIRD PARTY COLORS */
@value facebookColor: #1877f2;

/* BREAKPOINTS */
@value desktopWidth: 1600px;
@value extraLargeWidth: 1480px;
@value largeWidth: 1200px;
@value mediumWidth: 992px;
@value smallWidth: 768px;
@value mobileThreshold: 575px;

@value smallHeight: 840px;
@value mobileLowestHeight: 667px;

/* GENERAL VALUES */
@value modalWidth: 52.2rem;
@value toolbarControlsWidth: 40rem;
@value templateCardSmall: 16rem;
@value templateCardSmallInMobile: 32vw;

/* CUSTOM ANIMATIONS */
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInOpacityAndScale {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  30% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutOpacityAndScale {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }

  100% {
    transform: scale(.9);
    opacity: 0;
  }
}
