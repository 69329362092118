@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, midnightColor, royalColor, steelColor from variables;

.container {
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  overflow-y: scroll;
  width: 100%;
  max-height: 10rem;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    font-size: 1.4rem;
    color: slateColor;
    background: white;
    padding-bottom: 1rem;
    text-align: left;
    font-weight: 400;
    position: sticky;
    top: 0;
  }

  tbody tr {
    height: 4.9rem;
    padding: 1.6rem;
    background-color: rgba(83, 104, 127, .05);
    border-bottom: solid 1px steelColor;
  }

  tbody td {
    padding: 1.6rem;
    font-size: 1.4rem;
    color: slateColor;
  }
}

.linkCell {
  width: 79%;
  max-width: 0;
}

.linkContainer {
  display: flex;
  white-space: nowrap;
}

.linkText {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  color: royalColor;
  text-decoration: underline;
}

.spinnerContainer {
  flex: 1;
  padding-top: 3rem;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}
