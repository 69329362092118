@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.title {
  font-size: 1.8rem;
}

.results {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
  overflow-y: auto;
}

.emptyTitle {
  font-size: 2.4rem;
  text-align: center;
  font-weight: 300;
  margin-top: 3.3rem;
}

.emptySubtitle {
  padding-top: 1.6rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
}

@media screen and (max-width: smallWidth) {
  .emptyTitle {
    font-size: 1.6rem;
  }

  .emptySubtitle {
    font-size: 1.2rem;
  }
}
