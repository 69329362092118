.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.thumbnail {
  height: 20rem;
  margin-bottom: 1rem;
  align-self: flex-start;
}
