@value variables: "../../../assets/stylesheets/variables.scss";
@value royalColor, shadowSmall, smallWidth from variables;

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  color: white;
  background-color: royalColor;
  border-radius: 8px;
  box-shadow: shadowSmall;
  padding: 2.2rem 1.5rem 2.2rem 2.4rem;
  width: 41.5rem;
  left: 1.8rem;
  bottom: 1.4rem;
  animation: leftSlide .2s linear;

  // z-index has to be higher than controls options
  z-index: 103;
}

.close {
  background: none;
  border: none;
  position: absolute;
  right: .7rem;
  top: .8rem;
}

.tip {
  display: flex;
  flex-direction: row;
}

.icon {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.813rem;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 1.4rem;
}

.title {
  font-size: 2rem;
  margin-bottom: .5rem;
  line-height: 2.813rem;
}

.titleHighlight {
  font-weight: 700;
  font-size: 2rem;
  margin-right: .8rem;
}

.text {
  font-size: 1.8rem;
  line-height: 1.2;
  white-space: normal;
  text-align: justify;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.2rem;

  button {
    font-size: 1.4rem;
    letter-spacing: .5px;
    border: none;
    color: white;
    background: none;
    height: 3.1rem;
  }

  .gotIt {
    font-weight: 700;
    padding: 0 1rem;
  }
}

.pager {
  font-size: 1.4rem;
  margin-top: .25rem;
  letter-spacing: -1px;
  
  img {
    width: 3rem;
  }

  button {
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  button:disabled {
    opacity: .5;
  }
}

@keyframes leftSlide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: smallWidth) {
  .container {
    padding: 1.8rem 2.4rem;
    width: 90%;
  }

  .content {
    margin-left: 0;
  }

  .title {
    margin-bottom: 1.4rem;
    font-size: 1.6rem;
  }

  .icon {
    display: none;
  }

  .text {
    font-size: 1.4rem;
  }

  .actions {
    margin-top: 2.4rem;

    .gotIt {
      padding: 0;
      padding-left: 1rem;
    }
  }


  .pager {    
    img {
      width: 2rem;
    }
  }
}
