.toastSuccess {
  background-color: #d4f9e7;
  min-width: 23rem;
  max-width: 37rem;
  width: max-content;
  min-height: 4.4rem;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.toastSuccessMarkup {
  background-color: #d4f9e7;
  max-width: 37.4rem;
  min-height: 4.4rem;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.toastSuccessBody {
  font-family: 'Inter', sans-serif;
  color: #036029;
  font-size: 1.4rem;
}

// stylelint-disable
.Toastify__close-button--success {
  color: #036029;
  margin: auto;
}

.toastError {
  background-color: #ce3636;
  min-width: 27rem;
  min-height: 4.4rem;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.toastErrorBody {
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 1.4rem;
}

.Toastify__close-button--error {
  color: white;
  margin: auto;
}

.toastDefault {
  background-color: #eaeaea;
  min-width: 23rem;
  max-width: 37rem;
  width: max-content;
  height: 4.4rem;
  min-height: unset;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.toastDefaultBody {
  font-family: 'Inter', sans-serif;
  color: #1a1a1a;
  font-size: 1.4rem;
}

.Toastify__close-button--default {
  color: #1a1a1a;
  margin: auto;
}

.Toastify__toast-container {
  width: fit-content;
}

.Toastify__toast-body {
  padding: 6px 14px 6px 6px;
}

// stylelint-enable
