@value variables: "../../../assets/stylesheets/variables.scss";
@value crimsonColor, crismonFadedColor, mobileThreshold from variables;

.button {
  width: 50%;
  float: right;
}

.errorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  width: 100%;
  height: 4.7rem;
  border-radius: 3px;
  border: solid 1px crimsonColor;
  background-color: crismonFadedColor;
}

.error {
  color: crimsonColor;
  font-size: 1.4rem;
}

.link {
  color: crimsonColor;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (max-width: mobileThreshold) {
  .button {
    width: 100%;
  }
}
