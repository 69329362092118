@value variables: "../../../assets/stylesheets/variables.scss";
@value concreteColor, smallWidth from variables;

.container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 4.2rem;
  height: 6rem;
  align-items: center;
  background: white;
  border-bottom: solid 1px concreteColor;
}

.logoNav {
  background: none;
  border: none;
}

.logo {
  width: 2.4rem;
  height: 2.4rem;
}

.buttons {
  display: flex;
  align-items: center;
  height: 100%;
}

.button {
  margin: 0;
}

.button:first-of-type {
  margin-right: 1rem;
}

@media screen and (max-width: smallWidth) {
  .container {
    padding: 0 2rem;
  }

  .button {
    width: max-content;
  }
}
