@value variables: "../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  display: flex;
  align-items: center;
}

.link,
.export {
  margin-top: 0;
  margin-left: 1.6rem;
  width: max-content;
}

.linkButton {
  margin-top: 0;
  font-size: 1.4rem;
  padding: .7rem 1rem;
}

.cancel {
  margin-left: 1.6rem;
}

@media screen and (max-width: smallWidth) {
  .cancel {
    margin-left: .4rem;
  }

  .link {
    margin-left: .4rem;
  }
}
