@value variables: "../../../assets/stylesheets/variables.scss";
@value crimsonColor, midnightColor, slateColor, concreteColor, coalColor, extraLargeWidth, smallWidth from variables;

@import '../../../assets/stylesheets/mixins.scss';

.content {
  flex: 1;
  width: 100%;
  min-height: 0;
  flex-direction: column;
  background-color: transparent;
  padding: 0;
  overflow: auto;

  @include scrollbars(concreteColor, coalColor);
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header {
  display: flex;
  flex: .5;
  padding-bottom: 1.6rem;
}

.title {
  font-size: 2.4rem;
  font-weight: 300;
  color: midnightColor;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  max-width: 100%;
  margin: 0 1rem;
}

.imageIcon {
  width: 3.2rem;
  align-self: center;
}

.grid {
  flex-direction: column;
  color: midnightColor;
}

.pagination {
  flex: .5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.blankcard {
  height: 20vw;
  width: 20vw;
}

.label {
  color: midnightColor;
}

.labelDisabled {
  color: slateColor;
}

.spinnerContainer {
  height: 70vh;
}

.spinnerIcon {
  width: 4rem;
  height: 4rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

.error {
  font-size: 1.2rem;
  color: crimsonColor;
  margin-top: .8rem;
}

@media screen and (max-width: extraLargeWidth) {
  .blankcard {
    height: 17vw;
    width: 17vw;
  }
}

@media screen and (max-width: smallWidth) {
  .title {
    font-size: 1.6rem;
  }

  .imageIcon {
    width: 1.6rem;
  }

  .blankcard {
    height: 40vw;
    width: 40vw;
  }
}
