@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor from variables;

.container {
  display: flex;
  align-items: flex-end;
  padding: 0;

  > button {
    color: inherit;
    margin-left: 1rem;
  }
}

.emoji {
  margin-top: -.3rem;
  margin-left: 1rem;
}
