@value variables: "../../../assets/stylesheets/variables.scss";
@value crimsonColor, smallWidth from variables;

.form {
  display: flex;
  flex-direction: column;
}

.error {
  color: crimsonColor;
}

.button {
  align-self: center;
  margin: 0;
}

@media screen and (max-width: smallWidth) {
  .button {
    width: 100%;
  }
}
