@value variables: "../../../../assets/stylesheets/variables.scss";
@value seaweedColor, slateColor, titleColorLight from variables;

.form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}

.inputContainer {
  width: 100%;
  margin: 0 0 1.8rem;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxText {
  font-size: 1.2rem;
}

.submitContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
}

.button {
  margin-top: 0;
  width: 100%;
}

.buttonCancel {
  background: none;
  border: none;
  color: seaweedColor;
  font-size: 1.2rem;
  padding: 0;
  margin-top: 0;
}

.buttonCancel:hover:enabled {
  background: none;
  border: none;
}
