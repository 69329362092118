@value variables: "../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor, fogColor, shadowSmall, largeWidth, mediumWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 83.6rem;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.header {
  font-size: 1.8rem;
  color: midnightColor;
  margin-bottom: 1rem;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  color: slateColor;
  padding: 1.6rem 0;
  border-bottom: solid 1px fogColor;
}

.card {
  display: flex;
  align-items: center;

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.name {
  width: 100%;
}

@media screen and (max-width: largeWidth) {
  .container {
    width: 63.3rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }
}
