@value variables: "../../../assets/stylesheets/variables.scss";
@value steelColor, seaweedColor from variables;

.dots {
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 2rem;
}

.dot {
  width: .8rem;
  border-radius: 50%;
  margin-right: .4rem;
  height: .8rem;
  background-color: steelColor;
}

.dotActive {
  background-color: seaweedColor;
}
