@value variables: "../../../assets/stylesheets/variables.scss";
@value concreteColor from variables;

.container {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem 1rem .7rem;
  margin-bottom: -.6rem;
  width: 22rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: concreteColor;
  padding: .3rem;
  margin-left: 2rem;
}

.button {
  border: none;
  background: none;
  font-size: 1.4rem;
}

.input {
  width: 10rem;
  text-align: center;
  background: none;
  border: none;
}
