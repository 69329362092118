@value variables: "../../../assets/stylesheets/variables.scss";
@value fogColor, steelColor, slateColor, smallWidth from variables;

.search {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: 25rem;
  border-radius: 4px;
  border: solid 1px fogColor;
  padding: 0 .5rem;
  margin: 1rem 0 1rem 1rem;
  background-color: white;
}

.input {
  width: 100%;
  height: 3rem;
  border: none;
  background-color: transparent;
  font-size: 1.4rem;
  line-height: 1.2;
  color: steelColor;
}

.button {
  background: none;
  border: none;
  padding: 0;
}

.spinnerIcon {
  border: solid 2px transparent;
  border-top-color: slateColor;
  border-left-color: slateColor;
}

.searchIcon {
  width: 2.4rem;
  vertical-align: top;
}

@media screen and (max-width: smallWidth) {
  .title {
    font-size: 1.6rem;
  }

  .search {
    width: 30%;
    min-width: 15rem;
  }
}
