@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, mobileThreshold from variables;

$imageDimension: 10rem;

.container {
  display: flex;
  flex-wrap: wrap;
}

.loadingCard {
  width: $imageDimension;
  height: $imageDimension;
  margin: 0 0 1.4rem;
}

.loadingCard:nth-of-type(3n - 1) {
  margin: 0 1.8rem 1.4rem;
}

.cardContainer {
  display: flex;
  position: relative;
}

.cardContainer:nth-of-type(3n - 1) {
  margin: 0 1.8rem;
}

.deleteButton {
  position: absolute;
  top: .4rem;
  right: .4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 3.6rem;
  height: 3.6rem;
  background-color: midnightColor;
}

@media screen and (max-width: mobileThreshold) {
  $imageDimension: 8rem;

  .loadingCard {
    width: $imageDimension;
    height: $imageDimension;
    margin: 0 1rem 1.4rem;
  }

  .loadingCard:nth-of-type(3n - 1) {
    margin: 0 1rem 1.4rem;
  }

  .cardContainer {
    margin: 0;
  }

  .cardContainer:nth-of-type(3n - 1) {
    margin: 0;
  }

  .deleteButton {
    right: 1.4rem;
  }
}
