@value variables: "../../assets/stylesheets/variables.scss";
@value steelColor, midnightColor from variables;

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  text-decoration: none;
  padding: 1.2rem;
  border-radius: 4px;
  border: solid 1px steelColor;
  margin-bottom: .8rem;

  div {
    width: 1.3rem;
  }
}

.text {
  font-size: 1.4rem;
  color: midnightColor;
}
