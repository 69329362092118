@value variables: "../../assets/stylesheets/variables.scss";
@value  smallWidth from variables;

.modal {
  border-radius: 1rem;
}

.content {
  width: 49.1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  background: url('../../assets/images/blue-header-with-skyblue-circles.svg');
  width: 100%;
  background-size: cover;
  height: 12.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 33.7rem;
}

.headerTitle {
  color: white;
  font-size: 2.4rem;
  line-height: 1.2;
}

@media screen and (max-width: smallWidth) {
  .content {
    width: 90vw;
  }

  .headerTitle {
    font-size: 2rem;
    padding: 0 3rem;
  }
}
