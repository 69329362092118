.slider {
  display: flex;
  align-items: center;
}

.singleSlide,
.singleSlide img,
.singleSlide video {
  height: 100%;
  width: 100%;
  max-height: 90vh;
}

// stylelint-disable

:global(.slick-list) {
  width: 100%;
}

:global(.slick-dots li) {
  margin: 0;
}

:global(.slick-dots li button:before) {
  color: white;
  font-size: 9px;
}

:global(.slick-dots li.slick-active button:before) {
  color: white !important;
}

:global(.slick-slider) {
  -webkit-touch-callout: unset;
}

// stylelint-enable
