@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, concreteColor, coalColor, smallWidth from variables;

@import '../../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 2rem 2.4rem;
  overflow: hidden;
}

.title {
  font-size: 1.6rem;
  color: slateColor;
  margin-bottom: .8rem;
}

.subtitle {
  font-size: 1.4rem;
  color: slateColor;
}

.results {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  overflow-y: auto;
  margin: 2.4rem 0 8.2rem;

  @include scrollbars(concreteColor, coalColor);
}

.upgrade {
  justify-content: flex-start;
  margin-top: .8rem;
  border-radius: 4px;
  text-align: left;
  padding: 1rem 1.6rem;
}

.upgradeButton {
  margin: 1.4rem 0 0;
  width: 100%;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, transparent, concreteColor 30%);
  position: absolute;
  bottom: 2rem;
  left: 0;
  padding: 0 2.4rem;
}

.import {
  width: 100%;
  margin-top: 1rem;
}

.fullscreenImageContainer {
  margin: 0 !important;
}

.fullscreenImageButton {
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
}

.fullscreenImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: smallWidth) {
  .upgrade {
    padding: 1rem;
  }
}
