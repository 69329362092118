@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor, fogColor, midnightColor from variables;

.container {
  color: seaweedColor;
  border: solid 1px fogColor;
  border-radius: 4px;
  margin: 0 0 1rem;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.3rem;
  background-color: white;
  padding: .7rem 2rem;
  width: max-content;
  font-size: 1.6rem;
  letter-spacing: .5px;
  text-align: center;
}

.answer {
  margin: 0 0 2rem;
  opacity: .5;
}

.select {
  border: solid 1px fogColor;
  border-radius: 4px;
  padding: .7rem;
  margin-left: 1.2rem;
}

.options {
  width: 100%;
  top: calc(100% + .5rem);
}

.option {
  justify-content: center;
}

.selectedText {
  padding: 0 .7rem;
  color: midnightColor;
  font-size: 1.3rem;
}
