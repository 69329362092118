@value variables: "../../../assets/stylesheets/variables.scss";
@value modalWidth, slateColor, steelColor, midnightColor, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: modalWidth;
}

.header {
  background: url('../../../assets/images/blue-header-with-skyblue-circles.svg');
  width: 100%;
  background-size: cover;
  padding: 3.5rem 4rem;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem 4rem;
}

.title {
  font-size: 2.2rem;
  color: white;
}

.subtitle {
  font-size: 1.8rem;
  color: slateColor;
  margin-bottom: .8rem;
}

.text {
  font-size: 1.4rem;
  color: slateColor;
  margin-bottom: 2.4rem;
  line-height: 1.4;
}

.image {
  width: 100%;
}

.description {
  font-size: 1.4rem;
  line-height: 1.6;
  color: midnightColor;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2.4rem;
}

.button {
  margin-top: 0;
}

.buttonClose {
  background: none;
  border: none;
  color: slateColor;
  font-size: 1.4rem;
  padding: 0;
  margin-top: 0;
}

.buttonClose:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 100%;
    max-height: 90vh;
  }

  .modal {
    width: 90%;
  }

  .header {
    padding: 3.2rem 3rem;
  }

  .title {
    font-size: 2.2rem;
  }

  .body {
    padding: 2.4rem 3rem;
  }

  .actionButtons {
    flex-direction: column-reverse;
  }

  .button {
    width: 100%;
  }

  .link {
    margin-top: .8rem;
  }
}
