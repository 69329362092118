@value variables: "../../../assets/stylesheets/variables.scss";
@value coalColor, slateColor, midnightColor, fadeInOpacity, shadowSmall, extraLargeWidth, largeWidth, mobileThreshold from variables;

.planCard {
  height: 54rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.6rem;
  width: 100%;
}

.mainPlanCard {
  height: 62rem;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40.1rem;
  height: 100%;
  animation: fadeInOpacity .5s linear;
}

.mainCard {
  max-width: 43.3rem;
}

.top {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: shadowSmall;
  background-color: white;
  padding: 4rem;
}

.mainContainer {
  padding: 5rem;
}

.body {
  width: 100%;
  min-height: 21rem;
}

.header {
  display: flex;
  flex-direction: column;
  min-height: 13rem;
}

.plan {
  font-size: 2.4rem;
  text-align: center;
  color: slateColor;
  margin-bottom: .8rem;
}

.prices {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.price {
  font-size: 4.8rem;
  font-weight: 300;
  text-align: center;
  color: midnightColor;
}

.month {
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  color: midnightColor;
}

.promo {
  font-size: 1.4rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1.7rem;
  color: coalColor;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  span {
    flex: 1;
    margin-top: .22rem;
    font-size: 1.6rem;
    color: slateColor;
  }

  img {
    margin-left: 1.6rem;
  }
}

.comingSoon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.5rem;
  height: 2.4rem;
  color: white;
  border-radius: 4px;
  background-color: midnightColor;
  font-size: .8rem;
  margin-left: .5rem;
}

.selectPlan {
  width: max-content;
}

@media screen and (max-width: extraLargeWidth) {
  .container {
    padding: 3.2rem;
  }

  .mainContainer {
    padding: 4rem;
  }
}

@media screen and (max-width: largeWidth) {
  .card,
  .mainCard {
    max-width: 43.3rem;
  }

  .planCard {
    margin: 0 0 2.4rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .planCard {
    height: 50rem;
  }

  .mainPlanCard {
    height: 58rem;
  }

  .container {
    padding: 2rem;
  }

  .plan {
    font-size: 2rem;
  }

  .price {
    font-size: 4rem;
  }

  .item img {
    margin-left: 0;
  }

  .selectPlan {
    width: 100%;
  }
}
