@value variables: "../../../../assets/stylesheets/variables.scss";
@value facebookColor from variables;

.loginButton {
  background-color: facebookColor;
  color: white;
  height: 4rem;
  border-radius: 4px;
  border: none;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.28;
  font-weight: 600;
  font-size: 1.5rem;
  padding-right: 1.4rem;
  margin-top: 2rem;
}

.image {
  margin: .4rem .8rem .4rem .4rem;
  vertical-align: middle;
}
