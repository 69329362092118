.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.cancel {
  margin: 0;
  background-color: transparent;
}

.cancel:hover:enabled {
  background-color: transparent;
}

.done {
  margin: 0;
}
