@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor, emeraldColor, shadowSmall, steelColor, mobileThreshold from variables;

.container {
  margin: 0 0 0 2.6rem;
  align-items: center;
}

.options {
  box-shadow: shadowSmall;
  top: calc(100% + .8rem);

  // z-index has to be higher than controls options
  z-index: 103;
}

.option {
  text-align: left;
}

.button {
  background: seaweedColor;
  height: 3.3rem;
  padding: 0 1.2rem;
  border-radius: 4px;
}

.embedButton {
  border-radius: 0 4px 4px 0;
  padding: 0 .5rem;
  margin: 0;
}

.button:hover:enabled {
  background-color: emeraldColor;
}

.publishSingleButton {
  margin: 0 0 0 .4rem;
  padding: .7rem 1.2rem;
}

.publishButton {
  margin: 0;
  padding: 0 1.2rem;
  border-right: 1px solid white;
  border-radius: 4px 0 0 4px;
}

.publishText {
  color: white;
  font-size: 1.6rem;
  margin-right: 1rem;
}

.previewOption {
  border-bottom: solid 1px steelColor;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    margin: 0 0 0 .8rem;
  }

  .button {
    padding: 0 .8rem 0 1.2rem;
  }

  .publishButton {
    font-size: 1.4rem;
    padding: 0 .8rem 0;
  }

  .embedButton {
    padding: 0 .3rem;
  }

  .publishText {
    font-size: 1.4rem;
    margin-right: .4rem;
  }
}
