@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, seaweedColor from variables;

.checkbox {
  font-size: 1.4rem;
  color: slateColor;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: .5em;
  margin: 0;
  align-items: center;
}

.checkboxControl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6em;
  height: 1.6em;
  border: 1px solid fogColor;
  border-radius: 4px;
  background-color: white;
}

.checkboxControl:hover {
  cursor: pointer;
}

.checkboxInputDefault {
  margin: 0;
}

.checkboxInput {
  display: flex;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .checkboxControl {
      > span {
        display: block;
        width: 1.2em;
        height: 1.2em;
        background-color: seaweedColor;
        border-radius: 2px;
        border: 1px solid fogColor;
        margin: .12rem;
      }
    }
  }
}
