@value variables: "../../../../assets/stylesheets/variables.scss";
@value fogColor, slateColor, seaweedColor, mobileThreshold from variables;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  color: slateColor;
  padding: 1.6rem 0;
  border-bottom: solid 1px fogColor;
  width: 100%;
  flex-direction: column;
}

.card {
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.name,
.email {
  width: 17.5rem;
}

.accounts {
  width: 20rem;
  cursor: pointer;
}

.filler {
  width: 8rem;
}

.tooltip {
  top: unset;
  right: unset;
  left: unset;
  transform: none;
}

.buttonsContainer {
  display: flex;
  min-width: max-content;
  justify-content: flex-end;
}

.delete {
  padding: 0;
  border: none;
  background: none;
  font-size: 1.2rem;
  color: seaweedColor;
}
