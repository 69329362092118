@value variables: "../../../../assets/stylesheets/variables.scss";
@value coalColor from variables;

.container {
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  a {
    display: flex;
  }
}

.thumbnail {
  width: 100%;
}

.projectName {
  color: coalColor;
  font-size: 1.8rem;
  position: absolute;
  bottom: 3rem;
  left: 2.1rem;
  right: 2.1rem;
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 29.06%, rgba(255, 255, 255, .81) 86.71%);
}
