.container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.loading {
  width: 6rem;
  height: 3.3em;
}
