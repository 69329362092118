@value variables: "../../../assets/stylesheets/variables.scss";
@value coalColor, midnightColor, slateColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100%;
  width: 7rem;
  min-width: 7rem;
  background-color: coalColor;
  border-right: slateColor solid 1px;
}

.productTour {
  position: absolute;
  top: 10rem;
  left: 50%;
  z-index: 4;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    flex-direction: row;
    width: 100%;
    min-width: unset;
    height: 6.4rem;
    background-color: midnightColor;
    border-right: none;
    border-top: slateColor solid 1px;
  }

  .productTour {
    left: 30%;
    top: 0;
  }
}
