@value variables: "../../../../assets/stylesheets/variables.scss";
@value coalColor, concreteColor, slateColor, fadeInOpacity from variables;

@import '../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  align-items: center;
  height: 100%;
  animation: fadeInOpacity .2s linear;
  overflow-x: auto;

  @include scrollbars(coalColor, concreteColor, 1rem);
}

.group {
  position: static;
  display: flex;
  align-items: center;
  height: 100%;
  border-right: slateColor solid 1px;
}
