@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  align-items: center;
  height: 100%;
  border-left: slateColor solid 1px;
  padding-left: 1.5rem;
  width: 11rem;
}
