@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor, fogColor, smallWidth from variables;

.container {
  background-color: midnightColor;
  width: 100%;
}

.projectDetailsCard {
  margin: auto;
  background-color: white;
  border-radius: 1.6rem;
  overflow: hidden;
  margin-top: 5.8rem;
  height: 80vh;
  width: max-content;
  max-width: 90%;
}

.projectDetailsHeader {
  background-color: white;
  margin: 2.4rem 4.8rem;
}

.name {
  color: slateColor;
  padding: 2.4rem 0 0;
  font-size: 3.2rem;
  margin: 0;
}

.subnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a {
    text-decoration: none;
  }
}

.brandName {
  color: slateColor;
  font-size: 1.8rem;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shareButton {
  margin: 0;
  color: slateColor;
  border-color: fogColor;
}

.embed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  max-width: 100%;
}

.moreProjectsTitle {
  display: none;
}

.noMoreProjects {
  font-size: 1.4rem;
  padding: 1rem 1.8rem;
  color: slateColor;
}

.slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  max-width: 100%;
  height: 20vh;
  padding-top: .8rem;
  margin: auto;
}

.rightArrow {
  width: 3.2rem;
}

.leftArrow {
  width: 3.2rem;
}

.projectCardContainer {
  width: 15rem;
  padding: .8rem;
}

.staggerEmbed {
  padding: 1rem;
}

@media screen and (max-width: smallWidth) {
  .container {
    background-color: unset;
    min-height: 100vh;
  }

  .projectDetailsHeader {
    margin: 0 1.4rem 0 2.4rem;
    background-color: unset;
  }

  .projectDetailsCard {
    background-color: midnightColor;
    border-radius: 0;
    margin: 0;
    padding-top: 2rem;
    height: unset;
    width: 100%;
    max-width: unset;
  }

  .staggerEmbed {
    margin: 0 0 3.4rem;
    padding: 0;
  }

  .name {
    color: white;
    padding: 0;
  }

  .subnav {
    margin: 1.6rem 0 1rem;
    height: 3.2rem;
  }

  .brandName {
    color: white;
  }

  .shareButton {
    width: max-content;
    color: midnightColor;
  }

  .moreProjects {
    color: white;
  }

  .moreProjectsTitle {
    font-size: 2.4rem;
    font-weight: normal;
    padding: 2.4rem 1.8rem 1rem;
    margin: 0;
    display: block;
    color: slateColor;
  }
}
