@value variables: "../../assets/stylesheets/variables.scss";
@value midnightColor from variables;

.container {
  display: flex;
  align-items: center;
}

.spinner {
  border-top-color: midnightColor;
  border-left-color: midnightColor;
  border-width: 3px;
}

.text {
  margin-left: 1.4rem;
}

.emoji {
  margin-top: -.3rem;
  margin-left: 1rem;
}
