@value variables: "../../assets/stylesheets/variables.scss";
@value smallWidth, mobileThreshold from variables;

.button {
  margin: 0 0 0 1.6rem;
}

@media screen and (max-width: smallWidth) {
  .text {
    width: 100%;
  }

  .button {
    width: max-content;
    margin: 1rem 0 0;
  }
}

@media screen and (max-width: mobileThreshold) {
  .button {
    width: 95%;
    margin: 1rem auto 0;
  }
}
