@value variables: "../../../../assets/stylesheets/variables.scss";
@value fadeInOpacity, coalColor, concreteColor, slateColor from variables;

@import '../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  align-items: center;
  height: 100%;
  animation: fadeInOpacity .2s linear;
  overflow-x: auto;

  @include scrollbars(coalColor, concreteColor, 1rem);
}

.borderLeft {
  border-left: slateColor solid 1px;
}
