@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, smallWidth, mobileThreshold from variables;

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 9rem 3rem;
  background-color: concreteColor;
}

.logos {
  display: flex;
  flex-wrap: wrap;
}

.copyrights {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-left: 1.6rem;
  text-align: center;
}

.links {
  display: flex;
}

.link {
  display: flex;
  align-self: center;
  color: black;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: .5rem;
}

.link:first-child {
  margin-right: 1.5rem;
}

@media screen and (max-width: smallWidth) {
  .footer {
    justify-content: center;
  }

  .links {
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: mobileThreshold) {
  .footer {
    padding: 0 3.15rem 5rem;
  }

  .logos {
    justify-content: center;
  }

  .copyrights {
    width: 100%;
    margin-left: 0;
    margin-top: 1.5rem;
    text-align: center;
  }
}
