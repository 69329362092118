@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor from variables;

.page {
  min-height: 100%;
  background-color: concreteColor;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .2);
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}

.header {
  color: slateColor;
  font-size: 4rem;
}

.text {
  color: slateColor;
  font-size: 2rem;
  text-align: center;
}

.textSecondary {
  color: slateColor;
  margin-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
}

.image {
  width: 20rem;
  text-align: center;
}
