@value variables: "../../../../../assets/stylesheets/variables.scss";
@value midnightColor, modalWidth from variables;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: modalWidth;
  padding: 4rem;
}

.title {
  font-size: 2.4rem;
  color: midnightColor;
  margin-bottom: .8rem;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}

.locationSwitch {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}

.textSwitch {
  font-size: 1.6rem;
  margin-right: 1.6rem;
}

.publicUrlContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.publicUrlInput {
  flex: 1;
  margin-right: .7rem;
}

.copyLink {
  margin: 0 0 0 .7rem;
  height: 5.6rem;
}

.submitContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.button {
  margin-top: 0;
  width: calc(50% - .8rem);
}
