@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor from variables;

.container {
  position: absolute;
  border: seaweedColor 1px solid;
  border-style: dashed;
  outline: none;
  background: transparent;
  overflow-y: hidden;
}

.container::placeholder {
  color: var(--placeholder-color);
}
