@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 5.6rem;
  border-left: slateColor solid 1px;
  padding: 0 1.1rem;
  margin-left: 1.5rem;
}

button {
  border: none;
  background: none;
  padding: 0;
}
