@value variables: "../../../../assets/stylesheets/variables.scss";
@value coalColor, slateColor from variables;

.option {
  font-size: 1.4rem;
  line-height: 1.2;
  color: slateColor;
  margin: .4rem 0;
  padding: .4rem .8rem;
  border: none;
  background: none;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  &.active {
    transition: all .2s ease-in;
    background-color: coalColor;
    color: white;
  }
}
