@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowSmall, steelColor, slateColor, midnightColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.2rem;
}

.header {
  font-size: 1.8rem;
  color: midnightColor;
  margin-bottom: 2.2rem;
}

.content {
  display: flex;
  width: 100%;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  color: slateColor;
}

.value {
  font-size: 1.3rem;
  color: steelColor;
  flex-direction: column;

  a {
    color: steelColor;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }

  .content {
    flex-direction: column;
  }

  .title {
    margin-bottom: .3rem;
  }

  .value {
    display: flex;
    margin: 0;
    width: 100%;
  }
}
