@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowSmall, steelColor, concreteColor, slateColor, crimsonColor, midnightColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.2rem;
}

.header {
  font-size: 1.8rem;
  color: midnightColor;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 2.2rem;
}

.remove {
  width: 13rem;
  font-size: 1.2rem;
  color: crimsonColor;
  border: none;
  background: none;
  margin: 0;
  padding: .7rem 0;
}

.remove:hover:enabled {
  background-color: transparent;
}

.title {
  font-size: 1.8rem;
  text-align: center;
  color: slateColor;
  margin: 2rem 0 1.6rem;
}

.subtitle {
  max-width: 57rem;
  font-size: 1.4rem;
  line-height: 1.57;
  text-align: center;
  color: slateColor;
}

.loggedContainer {
  margin: 1.7rem 0 0;
  padding: 1.8rem 1.7rem 1.7rem 1.6rem;
  border-radius: 4px;
  border: dashed 1px steelColor;
  background-color: concreteColor;
  display: flex;
}

.connectedMessage {
  margin: .5rem 0 .5rem 1.5rem;
  font-size: 1.2rem;
  line-height: 1.83;
  color: slateColor;
}

.facebookIcon {
  margin-right: .8rem;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }
}
