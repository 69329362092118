.container {
  width: 20rem;
  background: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: -.6rem;
  box-sizing: content-box;
  padding: 1.5rem 1rem .7rem;
}

.collapsibleButton {
  width: 8.5rem;
  justify-content: space-between;
}

.options {
  right: unset;
  left: .5rem;
  width: 9rem;
}

.option {
  text-align: left;
}
