@value variables: "../../../../assets/stylesheets/variables.scss";
@value mediumWidth, smallWidth from variables;

.container {
  max-width: 62.8rem;
  max-height: 90vh;
}

.error {
  color: white;
  padding: 10rem 0;
  font-size: 1.4rem;
}

@media screen and (max-width: mediumWidth) {
  .container {
    max-width: 45rem;
  }
}

@media screen and (max-width: smallWidth) {
  .container {
    max-width: 95vw;
    height: unset;
    max-height: unset;
  }
}
