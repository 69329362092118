@value variables: "../../../assets/stylesheets/variables.scss";
@value fadeInOpacity, fadeOutOpacity, smallWidth from variables;

.container {
  position: relative;
  width: 100%;
  background: url('../../../assets/images/blue-header-with-skyblue-circles.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  border-radius: 8px;
}

.close {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  background: none;
  border: none;
  padding: 0;
}

.text {
  font-size: 3.2rem;
  line-height: 1.2;
  text-align: center;
  color: white;
  margin-bottom: 4rem;
}

.content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.mount {
  animation: fadeInOpacity .3s ease-in;
}

.unmount {
  animation: fadeOutOpacity .3s ease-out;
}

@media screen and (max-width: smallWidth) {
  .container {
    padding: 3rem;
  }

  .text {
    font-size: 2.4rem;
    margin-bottom: 2.1rem;
  }

  .content {
    display: inline;
    width: 100%;
    max-width: 30rem;
  }
}
