@value variables: "../../../assets/stylesheets/variables.scss";
@value forestColor, melonColor, slateColor from variables;

.subtitle {
  font-size: 1.4rem;
  line-height: 1.57;
  color: slateColor;
  margin: .1rem 0 2.4rem;
}

.successContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  width: 100%;
  height: 4.7rem;
  border-radius: 3px;
  border: solid 1px forestColor;
  background-color: melonColor;
  margin-bottom: 2.3rem;

  span {
    color: forestColor;
    font-size: 1.4rem;
  }
}

.button {
  align-self: center;
  width: 45%;
}
