@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, midnightColor, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.subnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8.4rem;
  background-color: white;
  padding: 0 4.2rem;
}

.title {
  font-size: 1.8rem;
  color: black;
  text-align: left;
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bannerContainer {
  margin-top: 2.4rem;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  flex: 1;
  padding: 0 4.6rem;
}

:global(.infinite-scroll-component__outerdiv) {
  flex: 1;
  display: flex;
}

.infiniteScroll {
  flex: 1;
}

.spinnerContainer {
  flex: 1;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

@media screen and (max-width: mobileThreshold) {
  .content {
    padding: 0 2rem;
  }

  .subnav {
    padding: 0 2rem;
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }
}
