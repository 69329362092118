@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowSmall, smallWidth from variables;

.container {
  background: white;
  box-shadow: shadowSmall;
  border-radius: 6px;
  padding: 3.2rem;
  margin-left: 3.2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

@media screen and (max-width: smallWidth) {
  .container {
    margin-left: 0;
    margin-top: 1.6rem;
  }
}
