@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  position: absolute;
  left: 4.5rem;
  top: 2.2rem;
  width: 9.6rem;
  height: 9.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .8rem;
  padding: 0 .4rem;
  border: dashed 1px white;
  background: none;
  border-radius: 4px;
  z-index: 3;

  img {
    width: 3.2rem;
  }
}

@media screen and (max-width: smallWidth) {
  .container {
    top: 2.5rem;
    width: 7rem;
    height: 7rem;
  }
}
