@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor, concreteColor from variables;

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: concreteColor;
}

.image {
  max-width: 94.5rem;
  width: 95%;
}

.text {
  font-size: 2.4rem;
  color: slateColor;
  margin-top: 4rem;
  text-align: center;
}

.goBack {
  font-size: 1.2rem;
  color: seaweedColor;
  text-decoration: none;
  margin-top: .8rem;
}
