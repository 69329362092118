@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, smallWidth from variables;

.container {
  display: flex;
  flex-direction: column;
  border-top: solid 1px fogColor;
  margin-top: 3.5rem;
}

.title {
  font-size: 1.8rem;
  color: slateColor;
  margin: 3.2rem 0 1.6rem;
}

.empty {
  font-size: 1.4rem;
  color: slateColor;
  margin: 1.6rem 0 2.4rem;
  text-align: center;
}

@media screen and (max-width: smallWidth) {
  .title {
    font-size: 1.4rem;
  }
}
