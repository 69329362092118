@value variables: "../../../../assets/stylesheets/variables.scss";
@value steelColor, midnightColor, concreteColor, smallWidth from variables;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 3.3rem;
  background: none;
  border-radius: 4px;
  border: solid 1px steelColor;
  padding: .4rem 2.4rem;
  min-width: max-content;
}

.container:hover:enabled {
  background-color: concreteColor;
}

.input {
  display: none;
}

.upload {
  color: midnightColor;
  font-size: 1.6rem;
}

@media screen and (max-width: smallWidth) {
  .container {
    margin-top: 1rem;
  }
}
