@value variables: "../../assets/stylesheets/variables.scss";
@value royalColor, smallWidth from variables;

.alert {
  width: 100%;
  min-height: 5.6rem;
  background-color: royalColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 0 4rem;
  color: white;
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: smallWidth) {
  .alert {
    padding: 1rem;
  }
}
