@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, smallWidth, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 3.2rem 4.8rem;
}

.emoji {
  font-size: 3rem;
}

.text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: slateColor;
  max-width: 39rem;
  align-self: flex-start;
}

.content .text:nth-of-type(2n) {
  margin: 1rem 0 2.8rem;
}

.actionButton {
  width: 100%;
  margin-top: 2.8rem;
}

.dismissButton,
.dismissButton:hover:enabled {
  background: none;
  border: none;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  text-align: left;
}

@media screen and (max-width: smallWidth) {
  .dismissButton,
  .actionButton {
    width: max-content;
  }
}

@media screen and (max-width: mobileThreshold) {
  .actionButton {
    width: 80%;
  }
}
