@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, mediumWidth, largeWidth from variables;

.step1container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.headerInfo {
  flex-basis: 100%;
  height: 2.9rem;
  font-size: 2.4rem;
  color: slateColor;
  margin-bottom: 2.4rem;
}

.row {
  width: 100%;
  display: flex;
}

.inputNameContainer {
  width: 100%;
}

.inputEmployeeContainer {
  margin-left: 3.2rem;
  flex: 0 0 24rem;
}

.inputDescriptionContainer {
  margin-bottom: 2.4rem;
}

.inputDescriptionContainer textarea {
  height: 11rem;
}

.inputWebsiteContainer {
  max-width: 29.3rem;
}

@media screen and (max-width: mediumWidth) {
  .row {
    display: unset;
  }

  .inputEmployeeContainer {
    width: 100%;
    margin-left: unset;
    margin-bottom: 2.4rem;
  }
}
