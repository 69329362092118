@value variables: "../../../assets/stylesheets/variables.scss";
@value borderMediumColor from variables;

.container {
  width: 3.4rem;
  height: 3.4rem;
  flex: none;
  background-color: white;
  border: 1px solid borderMediumColor;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.content {
  width: .6rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 3.4rem;
}

.dot {
  width: .6rem;
  height: .6rem;
  background-color: borderMediumColor;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: .4rem;
  margin: auto;
}
