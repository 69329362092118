.container {
  position: relative;
  font-size: 1.2rem;
  padding: .6rem 1.2rem;
}

.spinner {
  left: 50%;
  margin-left: -1rem;
  top: 50%;
  margin-top: -1rem;
  position: absolute;
  z-index: 19;
  animation: spin 600ms linear infinite;
}

.icon {
  width: 2rem;
  height: 2rem;
  border: solid 4px transparent;
  border-top-color: white;
  border-left-color: white;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
