@value variables: "../../assets/stylesheets/variables.scss";
@value royalColor, mobileThreshold from variables;

.container {
  background: white;
  box-shadow: 0 1px 32px 0 rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.inputContainer {
  margin-bottom: 0;
  width: 15rem;
}

.inputContainer:first-child {
  margin-right: .5rem;
}

.inputContainer:nth-child(2) {
  margin-left: .5rem;
}

// stylelint-disable

:global(.nice-dates-day:before) {
  background-color: royalColor;
}

:global(.nice-dates-day:after) {
  border: 2px solid royalColor;
}

:global(.nice-dates-grid) {
  margin-top: 8px;
}

:global(.nice-dates-day_month) {
  font-size: 9px;
}

// stylelint-endisable

@media screen and (max-width: mobileThreshold) {
  .header {
    flex-direction: column;
  }

  .inputContainer {
    width: 25rem;
  }

  .inputContainer:first-child {
    margin-right: 0;
  }

  .inputContainer:nth-child(2) {
    margin-left: 0;
    margin-top: .8rem;
  }
}
