@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, fogColor, crimsonColor from variables;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.name {
  font-size: 1.4rem;
  border: none;
  color: slateColor;
  text-overflow: ellipsis;
  margin-bottom: .4rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid fogColor;
  width: 100%;
  border-radius: 4px;
  transition: all .2s ease;
}

.error {
  background-color: concreteColor;
  border: solid 1px crimsonColor;
  color: crimsonColor;
  padding: 1rem;
}

.errorLabel {
  color: crimsonColor;
  padding: .2rem .5rem;
  font-size: 1.2rem;
  height: 1.2rem;
  display: table;
  white-space: nowrap;
}
