@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, shadowSmall, mediumWidth, mobileThreshold from variables;

.date {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  height: 3.4rem;
  position: relative;
}

.collapsibleButton {
  background: white;
}

.options {
  border: none;
  box-shadow: shadowSmall;
  width: 15rem;
  left: 0;
}

.option {
  color: slateColor;
  font-size: 1.4rem;
  text-align: left;
}

.option:active {
  background: slateColor;
}

.text {
  color: slateColor;
  margin-left: .8rem;
  font-size: 1.2rem;
}

.customOption {
  border-top: solid 1px fogColor;
}

.datePicker {
  position: absolute;
  top: 100%;
  left: 15.1rem;
  width: max-content;
  z-index: 11;
}

@media screen and (max-width: mediumWidth) {
  .options {
    width: 12rem;
  }

  .datePicker {
    left: 12.1rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .datePicker {
    left: 2rem;
    top: 24rem;
  }
}
