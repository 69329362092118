@value variables: "../../assets/stylesheets/variables.scss";
@value concreteColor, slateColor, largeWidth, mediumWidth, mobileThreshold, shadowSmall from variables;

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.subnav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 8.4rem;
  background-color: white;
  padding: 0 4.2rem;
}

.title {
  font-size: 1.8rem;
  color: black;
  text-align: left;
  margin: 0;
  font-weight: 400;
}

.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  background-color: concreteColor;
  padding: 4.2rem 4.6rem;
}

.contentForm {
  display: flex;
  flex-direction: column;
  width: 83.6rem;
}

.cantEditSettingsMessage {
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
  font-size: 1.4rem;
  line-height: 1.57;
  text-align: center;
  color: slateColor;
}

@media screen and (max-width: largeWidth) {
  .contentForm {
    width: 63.3rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .contentForm {
    width: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    padding: 4.2rem 2rem;
  }

  .subnav {
    padding: 0 2rem;
  }
}
