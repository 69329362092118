@value variables: "../../../assets/stylesheets/variables.scss";
@value steelColor, slateColor from variables;

.menuContainer {
  margin: 4.8rem;
  width: 100%;
}

.logo {
  width: 4.8rem;
  height: 4.8rem;
  object-fit: contain;
}

.optionsContainer {
  margin-top: 6.4rem;
  width: 100%;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  margin: 0 1.6rem 0 0;
  background-color: white;
  border-radius: 50%;
}

.current > .circle {
  border: 1px solid black;
}

.past > .circle {
  background-color: black;
}

.current .number {
  color: black
}

.number {
  font-size: 1.4rem;
  color: steelColor;
}

.check {
  width: 2.4rem;
  height: 2.4rem;
}

.menuOption {
  width: 21.1rem;
  height: 1.9rem;
  margin: 0 2.7rem 0 0;
  font-size: 1.6rem;
  letter-spacing: 1px;
  color: slateColor;
}

.option {
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
}
