@value variables: "../../../../../assets/stylesheets/variables.scss";
@value modalWidth, slateColor, midnightColor, seaweedColor, smallWidth, mobileThreshold from variables;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: modalWidth;
  padding: 4rem;
}

.title {
  font-size: 2.4rem;
  color: midnightColor;
  margin-bottom: .8rem;
}

.subtitle {
  font-size: 1.6rem;
  line-height: 1.6;
  color: slateColor;
  margin-bottom: 1.4rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emailContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.emailInput {
  flex: 1;
}

.emailButton {
  margin: 0 0 0 1.2rem;
  height: 5.6rem;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2.4rem;
}

.button {
  margin-top: 0;
  width: 100%;
}

.buttonCancel {
  background: none;
  border: none;
  color: seaweedColor;
  padding: 0;
  margin-top: 0;
}

.buttonCancel:hover:enabled {
  background: none;
  border: none;
}

@media screen and (max-width: smallWidth) {
  .emailContainer {
    flex-direction: column;
  }

  .emailButton {
    margin: 0 0 2.4rem;
  }

  .emailInput {
    margin-bottom: .8rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    width: 100%;
  }

  .modal {
    width: 90%;
  }
}
