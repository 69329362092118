@value variables: "../../../assets/stylesheets/variables.scss";
@value emeraldColor from variables;

.updateUrlButton {
  margin: 0 1.4rem 0 0;
  padding: 1rem 0;
  border: none;
  background: none;
  font-size: 1.4rem;
  height: max-content;
  color: emeraldColor;
}
