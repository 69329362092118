@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, seaweedColor from variables;

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3.2rem 4.8rem;

  p {
    color: slateColor;
    margin: 2.4rem 0 0;
    text-align: center;
  }
}

.enableButton,
.viewProfileButton {
  border-radius: 2.8rem;
}

.locationText {
  max-width: 26rem;
}

.locationIcon {
  padding-left: 1rem;
  margin-top: 3.9rem;
}

.instructions {
  width: 30rem;

  hr {
    border-top: 1px solid fogColor;
    margin: 3.5rem 0 2.2rem;
  }

  ul {
    padding: 0 2.5rem;

    li {
      text-align: left;
    }
  }

  .link {
    color: seaweedColor;
    font-size: 1.2rem;
    text-decoration: none;
    margin-top: 3.5rem;
    text-align: center;
    display: block;
  }
}
