@value variables: "../../../../assets/stylesheets/variables.scss";
@value smallWidth from variables;

.container {
  display: flex;
  padding: 4.1rem 2.4rem 2rem;
}

.header {
  flex-direction: column;
  width: 100%;
}

.name {
  font-weight: 700;
  font-size: 3.2rem;
}

.projectsCounter {
  font-size: 1.4rem;
}

.headerActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.websiteButton {
  width: max-content;
}

@media screen and (max-width: smallWidth) {
  .container {
    flex-direction: column;
  }
}
