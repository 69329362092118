@value variables: "../../../assets/stylesheets/variables.scss";
@value shadowSmall, midnightColor, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border-radius: 4px;
  box-shadow: shadowSmall;
  background-color: white;
}

.header {
  font-size: 1.8rem;
  color: midnightColor;
  margin-bottom: 1rem;
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }
}
