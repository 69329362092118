@value variables: "../../../assets/stylesheets/variables.scss";
@value royalColor from variables;

.allSetHeader {
  flex-basis: 100%;
  height: 2.9rem;
  font-size: 3.2rem;
  color: royalColor;
  margin-bottom: 2.4rem;
}

.message {
  margin: 0;
  max-width: 61.8rem;
  line-height: 1.56;
  font-size: 1.8rem;
  text-align: left;
}

.launchEditor {
  width: 24.8rem;
  height: 4rem;
}
