@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor, largeWidth from variables;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.font {
  display: flex;
  flex-grow: 1;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
}

.name {
  width: 20rem;
  font-size: 1.8rem;
  overflow-wrap: break-word;
}

.characteristics {
  font-size: 1.4rem;
  color: slateColor;
}

.delete {
  margin: 0 1rem 0 0;
  border: none;
  background: none;
  color: seaweedColor;
}

.editButton {
  border: none;
  background: none;
  border-radius: 4px;
  transition: all .2s ease-in-out;
}

.editButton:hover {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .2);
}

@media screen and (max-width: largeWidth) {
  .content {
    flex-wrap: wrap;
  }
}
