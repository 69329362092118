@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor from variables;

.referrer {
  position: relative;
}

.text {
  font-size: 1.4rem;
  color: slateColor;
  font-weight: 300;
  margin-bottom: .3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  white-space: break-spaces;
  word-break: break-word;
  width: 130%;
}
