@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, modalWidth, smallWidth from variables;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: modalWidth;
  padding: 4rem;
}

.title {
  margin-bottom: 1.4rem;
  font-size: 1.8rem;
  color: slateColor;
}

@media screen and (max-width: smallWidth) {
  .content {
    width: 100%;
  }

  .modal {
    width: 90%;
    max-width: 61.8rem;
  }
}
