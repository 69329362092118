@value variables: "../../../assets/stylesheets/variables.scss";
@value crimsonColor, midnightColor, slateColor, smallWidth, mobileThreshold from variables;

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2.4rem 4rem;
  margin-bottom: 2.4rem;
  width: 100%;
}

.headerInfo {
  font-size: 1.8rem;
  color: midnightColor;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text {
  font-size: 1.4rem;
  color: slateColor;
}

.button {
  margin-top: 0;
  min-width: max-content;
  width: max-content;
  margin-left: .8rem;
  background-color: crimsonColor;
}

.button:hover:enabled {
  background-color: crimsonColor;
}

@media screen and (max-width: smallWidth) {
  .text {
    margin-right: 1rem;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    padding: 2.4rem 2rem;
  }

  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerInfo {
    margin-bottom: .8rem;
  }

  .button {
    margin-top: 0.8rem;
    margin-left: 0;
  }
}
