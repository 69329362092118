@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, shadowSmall, fadeInOpacity from variables;

.container {
  position: initial;
  width: 5.1rem;
}

.button {
  display: flex;
  background: none;
  border: none;
  justify-content: center;
  padding: 0.2rem 0 0;
  margin: 0 1rem 0 0;
}

.options {
  position: absolute;
  z-index: 2;
  top: 118%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 1rem 0;
  border-radius: 4px;
  box-shadow: shadowSmall;
  width: 6.9rem;
  animation: fadeInOpacity .25s linear;
}

.option {
  border: none;
  background: white;
  padding-bottom: .7rem;
}

.option:hover:not(.selected) {
  background: concreteColor;
}

.selected {
  background: slateColor;
}
