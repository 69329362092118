@value variables: "../../../assets/stylesheets/variables.scss";
@value midnightColor, fadeInOpacity, smallWidth from variables;

.errorContainer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 3.3rem;
  animation: fadeInOpacity .25s linear;
}

.errorTitle {
  font-size: 3.2rem;
  font-weight: 300;
  color: midnightColor;
}

.errorSubtitle {
  padding-top: 1.6rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  color: midnightColor;
}

@media screen and (max-width: smallWidth) {
  .errorTitle {
    font-size: 1.6rem;
  }

  .errorSubtitle {
    font-size: 1.2rem;
  }
}
