body {
  height: 100%;
  margin: 0;
  font-family: 'Inter', sans-serif;

  button {
    outline: none;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
  }

  button:disabled {
    cursor: default;
  }

  input {
    font-family: 'Inter', sans-serif;
    outline: none;
  }

  textarea {
    font-family: 'Inter', sans-serif;
    resize: none;
  }

  * {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
  }

  // Define ourselves the z-index of olark
  .olark-launch-button-wrapper {
    z-index: 100 !important;
  }
}

html {
  font-size: 10px;
  height: 100%;
}
