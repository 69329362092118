@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, fogColor from variables;

.container {
  display: flex;
  align-items: center;
  width: 6.7rem;
  height: 3.2rem;
  margin-left: 1rem;
  position: relative;
}

.input {
  font-size: 1.4rem;
  font-weight: 300;
  color: slateColor;
  padding-left: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: concreteColor;
  border: none;
}

.input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
}

.sufix {
  font-size: 1.4rem;
  font-weight: 300;
  color: slateColor;
  position: absolute;
  top: .9rem;
  right: 2rem;
  pointer-events: none;
}

.buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100%;
  border-left: 1px solid fogColor;
}

.button {
  background: none;
  border: none;
  padding: 0 .4rem;
  font-size: 1.2rem;
  color: slateColor;
}

.button:first-child {
  border-bottom: 1px solid fogColor;
}
