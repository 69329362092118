@value variables: "../../../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, fadeInOpacity from variables;

.container {
  display: flex;
  position: relative;
  height: 100%;
}

.button {
  border: none;
  background: none;
  padding: 0;
}

.options {
  padding: .8rem 0;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 9rem;
  background-color: white;
  border: solid 1px slateColor;
  animation: fadeInOpacity .3s linear;
}

.option {
  width: 100%;
  text-align: left;
  padding: .8rem;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: 300;
}

.option:hover {
  background: concreteColor;
}
