@value variables: "../../../../assets/stylesheets/variables.scss";
@value fadeInOpacity, coalColor, concreteColor from variables;

@import '../../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  height: 100%;
  animation: fadeInOpacity .2s linear;
  overflow-x: auto;

  @include scrollbars(coalColor, concreteColor, 1rem);
}
