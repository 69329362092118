@value variables: "../../../assets/stylesheets/variables.scss";
@value royalColor, shadowSmall, mediumWidth from variables;

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
  background-color: royalColor;
  border-radius: 8px;
  box-shadow: shadowSmall;
  padding: 2.2rem 3.2rem;
  width: 40rem;
}

.close {
  background: none;
  border: none;
  position: absolute;
  right: .8rem;
  top: .8rem;
}

.title {
  font-size: 1.8rem;
  margin-bottom: .5rem;
}

.subtitle {
  font-size: 1.4rem;
  line-height: 1.2;
  white-space: normal;
}

@media screen and (max-width: mediumWidth) {
  .container {
    padding: 1.8rem 2.4rem;
    width: 30rem;
  }

  .title {
    font-size: 1.6rem;
  }
}
