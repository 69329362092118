@value variables: "../../../assets/stylesheets/variables.scss";
@value slateColor, fogColor, shadowSmall, largeWidth, mediumWidth, smallWidth from variables;

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 29rem;
  max-width: 29rem;
  overflow: auto;
}

.option {
  width: 100%;
  border-top: 1px solid transparent;
  transition: border-top .15s ease-in-out;
}

.option:not(:first-child):not(.active) {
  border-top: 1px solid fogColor;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: slateColor;
  font-size: 1.6rem;
  border-radius: 6px;
  width: 100%;
  padding: 1rem 1.4rem;
  margin: 1rem 0;
  border: none;
  background-color: transparent;
  text-align: left;

  &.active {
    background-color: white;
    box-shadow: shadowSmall;
  }
}

.categories {
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-out;
}

.categoriesContent {
  margin: 1.4rem 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: largeWidth) {
  .list {
    min-width: 20rem;
    max-width: 20rem;
  }
}

@media screen and (max-width: mediumWidth) {
  .list {
    min-width: 15rem;
    max-width: 15rem;
  }
}

@media screen and (max-width: smallWidth) {
  .list {
    min-width: unset;
    max-width: unset;
    flex-grow: unset;
  }
}
