@value variables: "../../assets/stylesheets/variables.scss";
@value royalColor, crimsonColor, smallWidth, fadeInOpacity, fadeOutOpacity from variables;

.container {
  background-color: royalColor;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8rem 0 4rem;

  &.error {
    background-color: crimsonColor;
  }
}

.text {
  color: white;
}

.actionButton {
  margin: 0 0 0 1.6rem;
}

.close {
  position: absolute;
  top: calc(50% - 1.6rem);
  right: 4rem;
  background: none;
  border: none;
  padding: 0;
  width: 3.2rem;
}

.mount {
  animation: fadeInOpacity .2s linear;
}

.unmount {
  animation: fadeOutOpacity .2s linear;
}

@media screen and (max-width: smallWidth) {
  .container {
    padding: 1rem 4rem 1rem 1rem;
  }

  .close {
    right: 1rem;
  }

  .text {
    width: 100%;
    margin-bottom: 1rem;
  }

  .actionButton {
    width: max-content;
  }
}
