@value variables: "../../../../assets/stylesheets/variables.scss";
@value midnightColor, slateColor, smallWidth from variables;

.container {
  flex: 1;
}

.title {
  font-size: 1.4rem;
  color: slateColor;
  margin-bottom: 2.4rem;
}

.spinnerContainer {
  height: 100%;
  width: 100%;
}

.spinnerIcon {
  width: 6rem;
  height: 6rem;
  border: solid 2px transparent;
  border-top-color: midnightColor;
  border-left-color: midnightColor;
}

@media screen and (max-width: smallWidth) {
  .container {
    flex: unset;
    height: 40rem;
  }
}
