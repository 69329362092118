@value variables: "../../../../../assets/stylesheets/variables.scss";
@value slateColor, concreteColor, fogColor, shadowSmall from variables;

.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 3;
  width: 26rem;
  left: 0;
  top: 100%;
  padding: 1.6rem;
  background: white;
  border-radius: 4px;
  box-shadow: shadowSmall;
}

.header {
  display: flex;
  align-items: center;
}

.headerText {
  color: slateColor;
  font-size: 1.2rem;
  margin-right: 1.4rem;
}

.properties {
  margin-top: 1.4rem;
  display: flex;
  flex-wrap: wrap;
}

.property {
  background-color: concreteColor;
  border-radius: 2px;
  border: solid 1px fogColor;
  width: 47%;
  height: 3.2rem;
  margin: 0 .4rem;
  display: flex;
  justify-content: center;
  padding-left: 1.2rem;
}

.property:first-child {
  margin-left: 0;
}

.property:last-child {
  margin: .8rem 0 0;
}

.label {
  display: flex;
  align-items: center;
  width: 100%;
  color: slateColor;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.inputContainer {
  width: 100%;
  height: 100%;
  margin-left: 0;
}

.input {
  padding-left: .5rem;
}

.colorContainer {
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
}

.color {
  color: slateColor;
  font-size: 1.4rem;
  margin-left: .8rem;
}
