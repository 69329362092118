.container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.answerClassName {
  margin: 0;
  width: 49%;
}
