@value variables: "../../../../../assets/stylesheets/variables.scss";
@value seaweedColor from variables;

.combinations {
  display: flex;
  flex-direction: column;
  margin-top: 3.6rem
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3.2rem;
}

.title {
  font-size: 1.6rem;
  color: white;
}

.seeAll {
  font-size: 1.4rem;
  color: seaweedColor;
  border: none;
  background: none;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
