@value variables: "../../../assets/stylesheets/variables.scss";
@value coalColor, concreteColor, royalColor from variables;

@import '../../../assets/stylesheets/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: coalColor;
  padding: 3.6rem .8rem 2.4rem;
  position: relative;
  margin-top: 2.4rem;
}

.indicator {
  position: absolute;
  top: -1.5rem;
  background-color: royalColor;
  color: white;
  font-size: 1.4rem;
  border-radius: 4px;
  padding: .8rem 1.4rem;
}

.thumbs {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  @include scrollbars(coalColor, concreteColor);
}

.thumbs button:first-child {
  margin-left: auto;
}

.thumbs button:last-child {
  margin-right: auto;
}
