@value variables: "../../assets/stylesheets/variables.scss";
@value ironColor, smallWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  max-height: 100%;
  background-color: ironColor;
  position: relative;
  overflow-y: hidden;
}

.content {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 0;
}

.canvasContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-width: 0;
  overflow-x: hidden;
}

.error {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.6rem;
  text-align: center;
}

.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.spinner {
  width: 6rem;
  height: 6rem;
}

@media screen and (max-width: smallWidth) {
  .content {
    position: relative;
  }
}

@media screen and (max-width: mobileThreshold) {
  .content {
    flex-direction: column;
  }
}
