.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .9);
  padding: 4rem;
  z-index: 2001;
}

.close {
  border: none;
  float: right;
  background: none;
}

.title {
  font-size: 2.4rem;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-top: 5rem;
}

.text {
  font-size: 1.6rem;
  color: white;
  text-align: center;
  margin-top: .8rem;
}
