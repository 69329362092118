@value variables: "../../assets/stylesheets/variables.scss";
@value slateColor, seaweedColor, concreteColor, largeWidth, mobileThreshold from variables;

.page {
  display: flex;
  flex-direction: column;
  background-color: concreteColor;
  min-height: 100%;
}

.pageContent {
  flex: 1;
  padding: 4rem 0 6rem;
}

.logotype {
  margin-left: 4.8rem;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 8rem;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  padding-top: 3rem;
}

h1 {
  font-size: 1.8rem;
  color: slateColor;
  margin: 0 0 2.4rem;
  text-align: center;
}

.form {
  width: 47rem;
  display: flex;
  flex-direction: column;
  padding: 3.8rem 6.4rem;
  background-color: white;
}

.signinContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  span {
    color: slateColor;
    font-size: 1.4rem;
  }
}

.link {
  color: seaweedColor;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: .5rem;
}

@media screen and (max-width: largeWidth) {
  .container {
    justify-content: center;
  }
}

@media screen and (max-width: mobileThreshold) {
  .form {
    width: 97%;
    padding: 1.9rem 3.15rem;
    margin: 0 1rem;
  }
}
