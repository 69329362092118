@value variables: "../../../assets/stylesheets/variables.scss";
@value smallWidth, mobileThreshold from variables;

.container {
  display: flex;
  min-height: 100%;

  // z-index has to be higher than bottom menu
  z-index: 102;
}

@media screen and (max-width: smallWidth) {
  .container {
    position: absolute;
    top: 0;
    left: 7rem;
    max-height: 100%;
  }
}

@media screen and (max-width: mobileThreshold) {
  .container {
    left: 0;
    top: 6.4rem;
    min-height: unset;
    height: calc(100% - 6.4rem);
  }

  .unsetHeight {
    height: unset;
  }
}
