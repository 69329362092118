
.noProjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noProjectsIcon {
  margin: 5.3rem 0 1.6rem;
}

.noProjectsText {
  font-size: 1.8rem;
  font-weight: normal;
  margin-top: 0;
}
