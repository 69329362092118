@value variables: "../../assets/stylesheets/variables.scss";
@value royalColor from variables;

.ripple {
  position: absolute;
  width: 4rem;
  height: 4rem;
  left: 0;
  top: 0;
  opacity: 0;
  border-radius: 10rem;
  background-color: royalColor;
  background-image: linear-gradient(90deg, royalColor, royalColor);
  animation: ripple 1.8s infinite;

  &:nth-child(2) {
    animation-delay: .3s;
  }

  &:nth-child(3) {
    animation-delay: .6s;
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}
